<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template v-if="isAuth" #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p v-if="userData && userData.name" class="user-name font-weight-bolder mb-0">
          {{ userData.name }}
        </p>
        <span v-if="userData && userData.role && userData.role.name" class="user-status">{{
          userData.role.name
        }}</span>
      </div>
      <b-avatar
        v-if="userData && userData.profileImage"
        size="40"
        :src="userData.profileImage"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="userData && userData.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      v-if="userData && userData.username"
      :to="{
        name: 'userprofile',
        params: { username: userData.username },
      }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>{{ $t("dashboard.Profile") }}</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="MailIcon" class="mr-50" />
      <span>Inbox</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Task</span>
    </b-dropdown-item> -->
    <b-dropdown-item :to="{ name: 'meeting' }">
      <feather-icon size="16" icon="VideoIcon" class="mr-50" />
      <span>{{ $t("dashboard.StartMeeting") }}</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'bookmarks' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="BookmarkIcon" class="mr-50" />
      <span>{{ $t("dashboard.MyBookmarks") }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item :to="{ name: 'pages-account-setting' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>{{ $t("dashboard.Settings") }}</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Pricing</span>
    </b-dropdown-item> -->
    <b-dropdown-item :to="{ name: 'faq-land' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>{{ $t("dashboard.FAQ") }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t("dashboard.Logout") }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions } from "vuex";
import { getAuth, signOut } from "firebase/auth";
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ToastificationContent,
  },
  data() {
    return {
      avatarText,
    };
  },
  computed: {
    userData() {
      return this.$store.getters["auth/user"];
    },
    isAuth() {
      return this.$store.getters["auth/isAuth"];
    },
  },
  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.logoutAction()
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `${this.$t("logout.successTitle")}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `${this.$t("logout.successText")}`,
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `${this.$t("logout.successTitle")}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: error.message,
                },
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
      this.$router.push({ name: "index" });
    },
  },
};
</script>
