const nav = [
  { header: "dashboard.Navgation" },
  {
    title: "dashboard.BusinessLounge",
    route: "timeline",
    icon: "BookmarkIcon",
  },
  {
    title: "dashboard.rewardProgram",
    route: "Rewards",
    icon: "AwardIcon",
  },

  {
    title: "dashboard.Ideas",
    icon: "AwardIcon",
    children: [
      {
        title: "dashboard.Create",
        route: "idea-create",
      },
      {
        title: "dashboard.ListAll",
        route: "ideas-listAll",
      },
    ],
  },
  {
    title: "dashboard.Projects",
    icon: "BriefcaseIcon",
    children: [
      {
        title: "dashboard.Create",
        route: "project-create",
      },
      {
        title: "dashboard.ListAll",
        route: "project-listAll",
      },
    ],
  },
  {
    title: "dashboard.Products",
    icon: "BoxIcon",
    children: [
      {
        title: "dashboard.Create",
        route: "product-create",
      },
      {
        title: "dashboard.ListAll",
        route: "product-listAll",
      },
    ],
  },
  {
    title: "dashboard.Services",
    icon: "StarIcon",
    children: [
      {
        title: "dashboard.Create",
        route: "service-create",
      },
      {
        title: "dashboard.ListAll",
        route: "service-listAll",
      },
    ],
  },
  {
    title: "Support Tickets",
    icon: "HelpCircleIcon",
    children: [
      {
        title: "Support Tickets",
        route: "SupportTickets",
      },
    ],
  },
  {
    title: "dashboard.MySubscriptions",
    icon: "AwardIcon",
    route: "my-subscriptions",
  },
  {
    title: "dashboard.Setting",
    icon: "SettingsIcon",
    route: "pages-account-setting",
  },
  // {
  //   title: ('dashboard.Logout'),
  //   icon: 'LogOutIcon',
  // },
];
export default nav;
