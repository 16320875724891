import { render, staticRenderFns } from "./CertificationRepeater.vue?vue&type=template&id=aaa81510&scoped=true&"
import script from "./CertificationRepeater.vue?vue&type=script&lang=js&"
export * from "./CertificationRepeater.vue?vue&type=script&lang=js&"
import style0 from "./CertificationRepeater.vue?vue&type=style&index=0&id=aaa81510&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa81510",
  null
  
)

export default component.exports