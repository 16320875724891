<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
      <!-- <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
        /> -->
      <span :class="navScroll">{{ currentCurrency().shortName }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in currencies"
      :key="localeObj.id"
      @click="changeCurrency(localeObj)"
    >
      <b-img :src="localeObj.flag" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
// import store from "@/store/myCurrency/myCurrency";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },

  data() {
    return {
      defaultCurrency: {
        _id: "629f4f8a3715037845a8c6a8",
        name: "US Dollar",
        shortName: "USD",
        rate: "1",
        symbol: "$",
        symbolDirection: "left",
        flag: "https://res.cloudinary.com/dk7mx4do7/image/upload/v1666698953/flags/unitedState_ghla5p.png",
        id: "629f4f8a3715037845a8c6a8",
        value: "629f4f8a3715037845a8c6a8",
        text: "US Dollar",
      },
      navScroll: "nav-headerWhite",
      windowWidth: window.innerWidth,
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);

    if (this.windowWidth < 700) {
      this.navScroll = "nav-headerDark";
    }
  },

  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    ...mapGetters({
      currencies: "currency/getCurrencies",
    }),
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions({
      fetchCurrencies: "currency/retreiveCurrencies",
      changeMyCurrency: "myCurrency/changeMyCurrency",
    }),
    changeCurrency(data) {
      localStorage.setItem("currency", JSON.stringify(data));
      // console.log(this.$store.state.myCurrency.myCurrency);
      this.changeMyCurrency(data);
      // store.dispatch("retreiveMyCurrency", data);
      // window.location.reload();
      // this.$router.go();
    },
    currentCurrency() {
      // console.log(JSON.parse(localStorage.getItem("currency")));
      return JSON.parse(localStorage.getItem("currency"));
    },
    initialCurrency() {
      if (localStorage.getItem("currency") === null)
        localStorage.setItem("currency", JSON.stringify(this.defaultCurrency));
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      // console.log(this.windowWidth);
      if (this.windowWidth > 700) {
        if (window.scrollY > 50) {
          this.navScroll = "nav-headerDark";
        }
        if (window.scrollY <= 50) {
          this.navScroll = "nav-headerWhite";
        }
      } else {
        this.navScroll = "nav-headerDark";
      }
    },
  },
  // setup() {},
  mounted() {
    this.fetchCurrencies();
    this.initialCurrency();
    // if (localStorage.getItem("currency") === null)
    //   localStorage.setItem("currency", JSON.stringify(this.defaultCurrency));
    // console.log(this.currencies);
    // localStorage.setItem("defaultCurrency");
  },
};
</script>

<style scoped>
/* span.currentCurrency {
  color: #ffffff;
} */
span.nav-headerWhite {
}
span.nav-headerDark {
}
.dropdown-toggle::after {
  color: #ffffff;
}

[dir] .nav-item.b-nav-dropdown.dropdown.dropdown-language.currency-custom {
  margin-left: 10px;
}
ul.dropdown-menu.dropdown-menu-right.show {
  min-width: 8rem;
}
</style>
<style>
[dir] .currency-custom .dropdown-menu {
  min-width: 8rem;
}
</style>
