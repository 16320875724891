/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import i18n from "@/libs/i18n";

export default {
  namespaced: true,
  state: {
    locale: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en",
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
    // changeLang(state) {
    //   console.log(i18n.locale);
    //   state.locale = i18n.locale;
    // },
  },
  actions: {
    changeLang({ commit }) {
      commit("SET_LOCALE", i18n.locale);

      localStorage.setItem("language", i18n.locale);
    },
  },
  getters: {
    getLocale(state) {
      return state.locale;
    },
  },
};
