import axios from 'axios'

export default {
  namespaced: true,
  state: {
    industries: [],
  },
  mutations: {
    SET_INDUSTRIES(state, industries) {
      state.industries = industries
    },
  },
  actions: {
    async retreiveIndustries({ commit }) {
      // eslint-disable-next-line no-return-await
      return await axios.get('industries').then(response => {
        commit('SET_INDUSTRIES', response.data.data)
      })
    },
  },
  getters: {
    getCountries(state) {
      return state.industries
    },
  },
}
