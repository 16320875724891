/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    categories: [],
    industries: [],
    skills: [],
    companies: [],
    companyCount: null,
    freeLancers: [],
    freelancersCount: null,
    investors: [],
    investorsCount: null,
    advisors: [],
    advisorsCount: null,
    brokers: [],
    brokersCount: null,
    serviceProfile: null,
    productProfile: [],
    projectProfile: [],
    ideaProfile: [],
    plans: [],
    permissions: [],
    roles: [],
    currentVerifyEmail: null,
    redirectPaymentUrl: null,
    userInfo: null,
    isLoading: false,
    countryCode: null,
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_REDIRECT_PAYMENT_URL(state, redirectPaymentUrl) {
      state.redirectPaymentUrl = redirectPaymentUrl;
    },
    SET_CURRENT_VERIFY_EMAIL(state, currentVerifyEmail) {
      state.currentVerifyEmail = currentVerifyEmail;
    },
    SET_INDUSTRIES(state, industries) {
      state.industries = industries;
    },
    SET_SKILLS(state, skills) {
      state.skills = skills;
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies;
    },
    SET_COMPANY_COUNT(state, companyCount) {
      state.companyCount = companyCount;
    },
    SET_FREELANCERS(state, freeLancers) {
      state.freeLancers = freeLancers;
    },
    SET_FREELANCE_COUNT(state, freelancersCount) {
      state.freelancersCount = freelancersCount;
    },
    SET_INVESTORS(state, investors) {
      state.investors = investors;
    },
    SET_INVESTORS_COUNT(state, investorsCount) {
      state.investorsCount = investorsCount;
    },
    SET_ADVISORS(state, advisors) {
      state.advisors = advisors;
    },
    SET_ADVISORS_COUNT(state, advisorsCount) {
      state.advisorsCount = advisorsCount;
    },
    SET_SERVICE_PROFILE(state, serviceProfile) {
      state.serviceProfile = serviceProfile;
    },
    SET_PRODUCT_PROFILE(state, productProfile) {
      state.productProfile = productProfile;
    },
    SET_UPDATE_IDEA_PROFILE(state, updateIdea) {
      state.updateIdea = updateIdea;
    },
    SET_BROKERS(state, brokers) {
      state.brokers = brokers;
    },
    SET_BROKERS_COUNT(state, brokersCount) {
      state.brokersCount = brokersCount;
    },
    SET_PROJECT_PROFILE(state, projectProfile) {
      state.projectProfile = projectProfile;
    },
    SET_IDEA_PROFILE(state, ideaProfile) {
      state.ideaProfile = ideaProfile;
    },
    SET_PLANS(state, plans) {
      state.plans = plans;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_COUNTRY_CODE(state, countryCode) {
      state.countryCode = countryCode;
    },
  },
  actions: {
    setCountryCode({ commit }) {
      fetch("https://ipapi.co/json/")
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          // console.log(data.country_code);
          commit("SET_COUNTRY_CODE", data.country_code);
        });
    },
    setLoading({ commit }, data) {
      commit("SET_LOADING", data);
    },
    async retreiveCompanies({ commit }, data) {
      return axios.post("companies/search", data).then((response) => {
        commit("SET_COMPANIES", response.data.data);
        commit("SET_COMPANY_COUNT", response.data.count);
      });
    },
    async retreiveStatistics({ commit }) {
      return axios.get("dashboard");
    },
    async retreiveUserInfo(_, data) {
      return axios.get(`user/details/${data}`);
    },
    async registerInPlan(_, data) {
      return axios.post("checkout/", data);
    },
    async registerInTrialPlan(_, data) {
      return axios.post("trial/checkout/", data);
    },
    async capture({ commit }, data) {
      return axios.get(`payment/success/${data}`);
    },
    async contactUs({ commit }, data) {
      return axios.post("contacts", data);
    },
    async retreivePlans({ commit }) {
      commit("SET_LOADING", true);
      return axios.get("plans").then((response) => {
        commit("SET_PLANS", response.data.data);
        commit("SET_LOADING", false);
      });
    },
    async retreiveFreelancers({ commit }, data) {
      // eslint-disable-next-line no-return-await
      return axios.post("freelancers/search", data).then((response) => {
        commit("SET_FREELANCERS", response.data.data);
        commit("SET_FREELANCE_COUNT", response.data.count);
      });
    },
    async retreiveInvestors({ commit }, data) {
      // eslint-disable-next-line no-return-await
      return axios.post("investors/search", data).then((response) => {
        commit("SET_INVESTORS", response.data.data);
        commit("SET_INVESTORS_COUNT", response.data.count);
      });
    },
    async retreiveAdvisors({ commit }, data) {
      // eslint-disable-next-line no-return-await
      return axios.post("advisors/search", data).then((response) => {
        commit("SET_ADVISORS", response.data.data);
        commit("SET_ADVISORS_COUNT", response.data.count);
      });
    },
    async retreiveBrokers({ commit }, data) {
      // eslint-disable-next-line no-return-await
      return axios.post("brokers/search", data).then((response) => {
        commit("SET_BROKERS", response.data.data);
        commit("SET_BROKERS_COUNT", response.data.count);
      });
    },
    async retreiveUserActivities(_, data) {
      const newData = new FormData();
      newData.append("page", data.page);
      newData.append("perPage", data.perPage);
      newData.append("sortBy", data.sortBy);
      newData.append("sortDesc", data.sortDesc);
      return axios.post(`user/details/${data.id}/activities`, newData);
    },
    async retrieveUserProfiles(_, data) {
      const newData = new FormData();
      newData.append("page", data.page);
      newData.append("perPage", data.perPage);
      newData.append("sortBy", data.sortBy);
      newData.append("sortDesc", data.sortDesc);
      return axios.post(`user/details/${data.id}/profiles`, newData);
    },
    async retrieveUserComments(_, data) {
      const newData = new FormData();
      newData.append("page", data.page);
      newData.append("perPage", data.perPage);
      newData.append("sortBy", data.sortBy);
      newData.append("sortDesc", data.sortDesc);
      return axios.post(`user/details/${data.id}/comments`, newData);
    },
    async retrieveUserComplaint(_, data) {
      const newData = new FormData();
      newData.append("page", data.page);
      newData.append("perPage", data.perPage);
      newData.append("sortBy", data.sortBy);
      newData.append("sortDesc", data.sortDesc);
      return axios.post(`user/details/${data.id}/complaints`, newData);
    },
    async retrieveUserFollowers(_, data) {
      const newData = new FormData();
      newData.append("page", data.page);
      newData.append("perPage", data.perPage);
      newData.append("sortBy", data.sortBy);
      newData.append("sortDesc", data.sortDesc);
      return axios.get(`user/details/${data.id}/followers`, newData);
    },
    async retrieveUserReviews(_, data) {
      const newData = new FormData();
      newData.append("page", data.page);
      newData.append("perPage", data.perPage);
      newData.append("sortBy", data.sortBy);
      newData.append("sortDesc", data.sortDesc);
      return axios.get(`user/details/${data.id}/reviews`, newData);
    },
    async retrieveUserLogs(_, data) {
      const newData = new FormData();
      newData.append("page", data.page);
      newData.append("perPage", data.perPage);
      newData.append("sortBy", data.sortBy);
      newData.append("sortDesc", data.sortDesc);
      return axios.get(`user/details/${data.id}/activities`, newData);
    },
    async retrieveUserSubscriptions(_, data) {
      return axios.post(`user/details/${data}/subscriptions`);
    },
    async retreiveCategories({ commit }) {
      // eslint-disable-next-line no-return-await
      return axios.get("categories").then((response) => {
        commit("SET_CATEGORIES", response.data.data);
      });
    },
    async retreiveIndustries({ commit }) {
      // eslint-disable-next-line no-return-await
      return axios.get("industries").then((response) => {
        commit("SET_INDUSTRIES", response.data.data);
      });
    },
    async retreivePermissions({ commit }) {
      // eslint-disable-next-line no-return-await
      return axios.get("permissions").then((response) => {
        commit("SET_PERMISSIONS", response.data);
      });
    },
    async retreiveSkills({ commit }) {
      // eslint-disable-next-line no-return-await
      return axios.get("skills").then((response) => {
        commit("SET_SKILLS", response.data.data);
      });
    },
    async retreiveRoles({ commit }) {
      // eslint-disable-next-line no-return-await
      return axios.get("roles").then((response) => {
        commit("SET_ROLES", response.data);
      });
    },
    async createCompany(_, data) {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      // eslint-disable-next-line no-return-await
      return axios.post("companies", data);
    },
    async editCompany(_, data) {
      // eslint-disable-next-line no-return-await
      return axios.put("companies", data);
    },
    async createInvestor(_, data) {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      // eslint-disable-next-line no-return-await
      return axios.post("investors/", data);
    },
    async editInvestor(_, data) {
      // eslint-disable-next-line no-return-await
      return axios.put("investors/", data);
    },
    async createFreelancer(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("freelancers/", data);
    },
    async editFreelancer(_, data) {
      // eslint-disable-next-line no-return-await
      return axios.put("freelancers/", data);
    },
    async createBroker(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("brokers/", data);
    },
    async editBroker(_, data) {
      // eslint-disable-next-line no-return-await
      return axios.put("brokers/", data);
    },
    async createAdvisor(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("advisors", data);
    },
    async editAdvisor(_, data) {
      // eslint-disable-next-line no-return-await
      return axios.put("advisors", data);
    },
    async createPartner(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("partners/", data);
    },
    async editPartner(_, data) {
      // eslint-disable-next-line no-return-await
      return axios.put("partners/", data);
    },
    async createIdea(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("ideaProfile", data);
    },
    async retreiveIdeaProfile(_, profileId) {
      // eslint-disable-next-line no-return-await
      return await axios.get(`profiles/${profileId}`);
    },
    async deleteRole(_, id) {
      // eslint-disable-next-line no-return-await
      return await axios.delete(`roles/${id}`);
    },
    async deletePlans(_, id) {
      // eslint-disable-next-line no-return-await
      return await axios.delete(`plans/${id}`);
    },
    async deleteFile(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.delete("deleteFiles", { data });
    },
    async deleteUserFile(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.delete("deleteUserFiles", { data });
    },
    async editIdea(_, data) {
      // eslint-disable-next-line no-return-await
      // console.log(data.profileId);
      // eslint-disable-next-line no-return-await
      return await axios.put("ideaProfile", data);
    },
    async createProject(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("projectProfile", data);
    },
    async retreiveProjectProfile({ commit }, profileId) {
      // eslint-disable-next-line no-return-await
      return await axios.get(`profiles/${profileId}`).then((response) => {
        commit("SET_PROJECT_PROFILE", response.data.data);
      });
    },
    async editProject(_, data) {
      // eslint-disable-next-line no-return-await
      // console.log(data.profileId);
      return await axios.put("projectProfile", data);
    },
    async createProduct(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("productProfile", data);
    },
    async retreiveProductProfile({ commit }, profileId) {
      // eslint-disable-next-line no-return-await
      return await axios.get(`profiles/${profileId}`).then((response) => {
        commit("SET_PRODUCT_PROFILE", response.data.data);
      });
    },
    async editProduct(_, data) {
      // eslint-disable-next-line no-return-await
      // console.log(data.profileId);
      return axios.put("productProfile", data);
    },
    async createService(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("serviceProfile", data);
    },
    async retreiveServiceProfile({ commit }, profileId) {
      // eslint-disable-next-line no-return-await
      return await axios.get(`profiles/${profileId}`).then((response) => {
        commit("SET_SERVICE_PROFILE", response.data.data);
      });
    },
    async editService(_, data) {
      // eslint-disable-next-line no-return-await
      // console.log(data.profileId);
      return axios.put("serviceProfile", data);
    },
    async editAuctionStatus(_, data) {
      // eslint-disable-next-line no-return-await
      // console.log(data.profileId);
      return axios.put(
        `auctions/changeStatus/${data.auctionId}`,
        data.formData
      );
    },
    async createBlog(_, data) {
      // eslint-disable-next-line no-return-await
      return await axios.post("posts", data);
    },
  },
  getters: {
    getLoading(state) {
      return state.isLoading;
    },

    getIdeaCategories(state) {
      const formatCategories = [];
      state.categories.forEach((element) => {
        if (element.type === "idea") {
          formatCategories.push({
            // eslint-disable-next-line no-underscore-dangle
            value: element.value,
            text:
              store.state.locale.locale == "ar"
                ? element.arabicName
                : element.text,
          });
        }
      });
      return formatCategories;
    },
    getAllIndustries(state) {
      return state.industries;
    },
    getProjectCategories(state) {
      const formatCategories = [];
      state.categories.forEach((element) => {
        if (element.type === "project") {
          formatCategories.push({
            // eslint-disable-next-line no-underscore-dangle
            value: element.value,
            text:
              store.state.locale.locale == "ar"
                ? element.arabicName
                : element.text,
          });
        }
      });
      return formatCategories;
    },
    getServiceCategories(state) {
      const formatCategories = [];
      state.categories.forEach((element) => {
        if (element.type === "service") {
          formatCategories.push({
            // eslint-disable-next-line no-underscore-dangle
            value: element.value,
            text:
              store.state.locale.locale == "ar"
                ? element.arabicName
                : element.text,
          });
        }
      });
      return formatCategories;
    },
    // getUserInfo(state) {
    //   const formatUserInfo = {
    //     username: state.userInfo.username,
    //     email: state.userInfo.email,
    //     isActive: state.userInfo.isActive,
    //     isVerified: state.userInfo.isVerified,
    //     userType: state.userInfo.userType,
    //     roleName: state.userInfo.role.name,
    //     joined: state.userInfo.createdAt,
    //     imgURL: '',
    //   }
    //   if (state.userInfo.userType === 'broker') {
    //     formatUserInfo.imgURL = state.userInfo.broker.files[0].fileUrl
    //   } else if (state.userInfo.userType === 'company') {
    //     formatUserInfo.imgURL = state.userInfo.company.files[0].fileUrl
    //   } else if (state.userInfo.userType === 'freelancer') {
    //     formatUserInfo.imgURL = state.userInfo.freelancer.files[0].fileUrl
    //   } else if (state.userInfo.userType === 'advisor') {
    //     formatUserInfo.imgURL = state.userInfo.advisor.files[0].fileUrl
    //   } else if (state.userInfo.userType === 'partner') {
    //     formatUserInfo.imgURL = state.userInfo.partner.files[0].fileUrl
    //   } else if (state.userInfo.userType === 'investor') {
    //     formatUserInfo.imgURL = state.userInfo.investor.files[0].fileUrl
    //   }

    //   return formatUserInfo
    // },
    getProductCategories(state) {
      const formatCategories = [];
      state.categories.forEach((element) => {
        if (element.type === "product") {
          formatCategories.push({
            // eslint-disable-next-line no-underscore-dangle
            value: element.value,
            text:
              store.state.locale.locale == "ar"
                ? element.arabicName
                : element.text,
          });
        }
      });
      return formatCategories;
    },
    getCompanyCategories(state) {
      const formatCategories = [];
      state.categories.forEach((element) => {
        if (element.type === "company") {
          formatCategories.push({
            // eslint-disable-next-line no-underscore-dangle
            value: element.value,
            text:
              store.state.locale.locale == "ar"
                ? element.arabicName
                : element.text,
          });
        }
      });
      return formatCategories;
    },
    getInvestorCategories(state) {
      const formatCategories = [];
      state.categories.forEach((element) => {
        if (element.type === "investor") {
          formatCategories.push({
            // eslint-disable-next-line no-underscore-dangle
            value: element.value,
            text:
              store.state.locale.locale === "ar" &&
              element.arabicName !== undefined
                ? element.arabicName
                : element.text,
          });
        }
      });
      return formatCategories;
    },
    getAllSkills(state) {
      const formatSkills = [];
      state.skills.forEach((element) => {
        formatSkills.push({
          // eslint-disable-next-line no-underscore-dangle
          value: element._id,
          text:
            store.state.locale.locale === "ar" &&
            element.arabicName !== undefined
              ? element.arabicName
              : element.name,
        });
      });
      return formatSkills;
    },
    getAllSkillsId(state) {
      const formatSkills = [];
      state.skills.forEach((element) => {
        formatSkills.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          label:
            store.state.locale.locale === "ar" &&
            element.arabicName !== undefined
              ? element.arabicName
              : element.name,
        });
      });
      return formatSkills;
    },
    getFreelancerSkills(state) {
      return state.skills;
    },
    getAllSkillsAsTree(state) {
      const formatSkills = [];
      state.skills.forEach((element) => {
        formatSkills.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          label: element.name,
          children: [],
        });
      });
      return formatSkills;
    },
    getAllCompanies(state) {
      const formatCompanies = [];
      state.companies.forEach((element) => {
        const stateObj = JSON.parse(element.company.address.state);
        formatCompanies.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          name: element.company.name,
          establishDate: element.company.establishDate,
          description: element.company.description,
          highlights: element.company.highlights,
          industries: element.company.industries,
          country:
            store.state.locale.locale == "ar"
              ? element.company.address.country.arabicName
              : element.company.address.country.name,
          state:
            store.state.locale.locale == "ar"
              ? stateObj.arabicName != undefined
                ? stateObj.arabicName
                : stateObj.name
              : stateObj.name,

          city: element.company.address.city,
          avgMonthlySales: element.company.avgMonthlySales,
          ebitda: element.company.ebitda,
          companyType: element.company.companyType,
          companyCategory:
            store.state.locale.locale == "ar"
              ? element.company.companyCategory.arabicName
              : element.company.companyCategory.name,
          currency: element.company.currency
            ? element.company.currency.symbol
            : "$",
          // currency:
          //   element.company && element.company.currency
          //     ? element.company.currency.symbol
          //     : store.state.locale.locale == "ar"
          //     ? "مزاد"
          //     : "Auction",
          price:
            element.company && element.company.price
              ? element.company.price
              : 1,
          username: element.username,
          ratingNumber: element.ratingNumber,
          companyImg:
            element.company.files.length > 0
              ? element.company.files[0].fileUrl
              : null,
        });
      });
      return formatCompanies;
    },
    getCompanyCount(state) {
      return state.companyCount;
    },
    getAllFreelancers(state) {
      const formatFreelancers = [];
      state.freeLancers.forEach((element) => {
        formatFreelancers.push({
          id: element._id,
          name: element.freelancer.fullName,
          username: element.username,
          highlights: element.freelancer.highlights,
          nationality:
            store.state.locale.locale == "ar"
              ? element.freelancer.nationality.arabicName
              : element.freelancer.nationality.name,
          skills: element.freelancer.skills,
          industries: element.freelancer.industries,
          ratingNumber: element.ratingNumber,
          freelancerImg:
            element.freelancer.files.length > 0
              ? element.freelancer.files[0].fileUrl
              : null,
        });
      });
      return formatFreelancers;
    },
    getFreeLancersCount(state) {
      return state.freelancersCount;
    },
    getAllInvestors(state) {
      const formatInvestors = [];
      state.investors.forEach((element) => {
        formatInvestors.push({
          id: element._id,
          name: element.investor.legalName ? element.investor.legalName : "",
          username: element.username,
          establishDate: element.createdAt,
          highlights: element.investor.highlights,
          industries: element.investor.industries,
          country: element.investor.address,
          ratingNumber: element.ratingNumber,
          investorImg:
            element.investor.files.length > 0
              ? element.investor.files[0].fileUrl
              : null,
          interstedLocations: element.investor.interstedLocations
            ? element.investor.interstedLocations
            : [],
          categories:
            store.state.locale.locale === "ar" &&
            element.investor.investorCategory.arabicName !== undefined
              ? element.investor.investorCategory.arabicName
              : element.investor.investorCategory.name,
          min: element.investor.investmentSize.min
            ? element.investor.investmentSize.min
            : 0,
          max: element.investor.investmentSize.max
            ? element.investor.investmentSize.max
            : 0,

          symbol: element.investor.investorCurrency.symbol
            ? element.investor.investorCurrency.symbol
            : "aed",
        });
      });
      return formatInvestors;
    },
    getInvestorsCount(state) {
      return state.investorsCount;
    },
    getAllAdvisors(state) {
      const formatAdvisors = [];
      state.advisors.forEach((element) => {
        formatAdvisors.push({
          id: element._id,
          name: element.advisor.fullName,
          username: element.username,
          highlights: element.advisor.highlights,
          nationality: element.advisor.address,
          skills: element.advisor.skills,
          industries: element.advisor.industries,
          ratingNumber: element.ratingNumber,
          freelancerImg:
            element.advisor.files.length > 0
              ? element.advisor.files[0].fileUrl
              : null,
        });
      });
      return formatAdvisors;
    },
    getAdvisorsCount(state) {
      return state.advisorsCount;
    },
    getAllBrokers(state) {
      const formatBrokers = [];
      state.brokers.forEach((element) => {
        formatBrokers.push({
          id: element._id,
          name: element.broker.fullName,
          username: element.username,
          highlights: element.broker.highlights,
          nationality:
            store.state.locale.locale == "ar"
              ? element.broker.nationality.arabicName
              : element.broker.nationality.name,
          previousWork: element.broker.previousWork,
          industries: element.broker.industries,
          address: element.broker.address,
          ratingNumber: element.ratingNumber,
          brokerImg:
            element.broker.files.length > 0
              ? element.broker.files[0].fileUrl
              : null,
        });
      });
      return formatBrokers;
    },
    getBrokersCount(state) {
      return state.brokersCount;
    },

    getServiceProfile(state) {
      const formatServiceProfile = {};

      formatServiceProfile._id = state.serviceProfile._id
        ? state.serviceProfile._id
        : "";
      formatServiceProfile.name = state.serviceProfile.name
        ? state.serviceProfile.name
        : "";
      formatServiceProfile.typeofOwner = {
        value:
          state.serviceProfile.typeofOwner === "individual"
            ? "individual"
            : "group",
        text:
          state.serviceProfile.typeofOwner === "individual"
            ? "Individual"
            : "Group",
      };

      if (state.serviceProfile.address.profileCountry._id) {
        formatServiceProfile.profileCountry = {
          value: state.serviceProfile.address.profileCountry._id,
          text: state.serviceProfile.address.profileCountry.text,
        };
      } else {
        formatServiceProfile.profileCountry = {
          value: "",
          text: "",
        };
      }

      formatServiceProfile.profileState = {
        value: state.serviceProfile.address.profileState,
        text: "",
      };
      formatServiceProfile.profileCity = state.serviceProfile.address
        .profileCity
        ? state.serviceProfile.address.profileCity
        : "";

      formatServiceProfile.highlights = state.serviceProfile.highlights
        ? state.serviceProfile.highlights
        : "";
      formatServiceProfile.detailsofOwner = state.serviceProfile.detailsofOwner
        ? state.serviceProfile.detailsofOwner
        : "";
      formatServiceProfile.description = state.serviceProfile.description
        ? state.serviceProfile.description
        : "";

      formatServiceProfile.category = {
        value: state.serviceProfile.category.value,
        text: state.serviceProfile.category.text,
      };

      formatServiceProfile.facebook = state.serviceProfile.socialMedia.facebook
        ? state.serviceProfile.socialMedia.facebook
        : "";
      formatServiceProfile.linkedIn = state.serviceProfile.socialMedia.linkedIn
        ? state.serviceProfile.socialMedia.linkedIn
        : "";
      formatServiceProfile.twitter = state.serviceProfile.socialMedia.twitter
        ? state.serviceProfile.socialMedia.twitter
        : "";
      formatServiceProfile.otherURLS = state.serviceProfile.socialMedia
        .otherURLS
        ? state.serviceProfile.socialMedia.otherURLS
        : "";

      formatServiceProfile.blockchainExchange = {
        value:
          state.serviceProfile.blockchainExchange === "true" ? "true" : "false",
        text: state.serviceProfile.blockchainExchange === "true" ? "Yes" : "No",
      };

      if (state.serviceProfile.service.serviceType) {
        if (state.serviceProfile.service.serviceType === "contract") {
          formatServiceProfile.serviceType = {
            value: state.serviceProfile.service.serviceType,
            text: "Contract",
          };
        } else if (state.serviceProfile.service.serviceType === "hourly") {
          formatServiceProfile.serviceType = {
            value: state.serviceProfile.service.serviceType,
            text: "Hourly",
          };
        } else if (state.serviceProfile.service.serviceType === "daily") {
          formatServiceProfile.serviceType = {
            value: state.serviceProfile.service.serviceType,
            text: "Daily",
          };
        } else if (state.serviceProfile.service.serviceType === "weekly") {
          formatServiceProfile.serviceType = {
            value: state.serviceProfile.service.serviceType,
            text: "Weekly",
          };
        } else if (state.serviceProfile.service.serviceType === "monthly") {
          formatServiceProfile.serviceType = {
            value: state.serviceProfile.service.serviceType,
            text: "Monthly",
          };
        } else if (state.serviceProfile.service.serviceType === "yearly") {
          formatServiceProfile.serviceType = {
            value: state.serviceProfile.service.serviceType,
            text: "Yearly",
          };
        } else {
          formatServiceProfile.serviceType = {
            value: "",
            text: "",
          };
        }
      }
      if (state.serviceProfile.service.serviceLocation) {
        if (state.serviceProfile.service.serviceLocation === "desk") {
          formatServiceProfile.serviceLocation = {
            value: state.serviceProfile.service.serviceLocation,
            text: "Desk",
          };
        } else if (state.serviceProfile.service.serviceLocation === "field") {
          formatServiceProfile.serviceLocation = {
            value: state.serviceProfile.service.serviceLocation,
            text: "Field",
          };
        } else if (state.serviceProfile.service.serviceLocation === "online") {
          formatServiceProfile.serviceLocation = {
            value: state.serviceProfile.service.serviceLocation,
            text: "Online",
          };
        } else {
          formatServiceProfile.serviceLocation = {
            value: "",
            text: "",
          };
        }
      }
      if (state.serviceProfile.saleType) {
        if (state.serviceProfile.saleType === "1") {
          formatServiceProfile.saleType = {
            value: state.serviceProfile.saleType,
            text: "My Own Price",
          };
        } else if (state.serviceProfile.saleType === "2") {
          formatServiceProfile.saleType = {
            value: state.serviceProfile.saleType,
            text: "Open Auction",
          };
        } else if (state.serviceProfile.saleType === "3") {
          formatServiceProfile.saleType = {
            value: state.serviceProfile.saleType,
            text: "Timed Auction",
          };
        } else {
          formatServiceProfile.saleType = {
            value: "",
            text: "",
          };
        }
      }

      formatServiceProfile.priceNumber = state.serviceProfile.price.priceNumber
        ? state.serviceProfile.price.priceNumber
        : "";

      formatServiceProfile.currencyId = {
        value: state.serviceProfile.price.currencyId._id,
        text: state.serviceProfile.price.currencyId.text,
      };

      const profileIndustriesArray = [];
      state.serviceProfile.profileIndustries.forEach(
        (profileIndustries, index) => {
          profileIndustriesArray.push(profileIndustries._id);
          // console.log(value);
          // console.log(index);
        }
      );
      formatServiceProfile.profileIndustries = state.serviceProfile
        .profileIndustries
        ? profileIndustriesArray
        : [];

      return formatServiceProfile;
    },
    getProductProfile(state) {
      const formatProductProfile = {};

      formatProductProfile._id = state.productProfile._id
        ? state.productProfile._id
        : "";
      formatProductProfile.name = state.productProfile.name
        ? state.productProfile.name
        : "";
      formatProductProfile.typeofOwner = {
        value:
          state.productProfile.typeofOwner == "individual"
            ? "individual"
            : "group",
        text:
          state.productProfile.typeofOwner == "individual"
            ? "Individual"
            : "Group",
      };
      if (state.productProfile.address.profileCountry._id) {
        formatProductProfile.profileCountry = {
          value: state.productProfile.address.profileCountry._id,
          text: state.productProfile.address.profileCountry.text,
        };
      } else {
        formatProductProfile.profileCountry = {
          value: "",
          text: "",
        };
      }

      formatProductProfile.profileState = {
        value: state.productProfile.address.profileState,
        text: "",
      };
      formatProductProfile.profileCity = state.productProfile.address
        .profileCity
        ? state.productProfile.address.profileCity
        : "";

      formatProductProfile.category = {
        value: state.productProfile.category.value,
        text: state.productProfile.category.text,
      };

      formatProductProfile.highlights = state.productProfile.highlights
        ? state.productProfile.highlights
        : "";
      formatProductProfile.detailsofOwner = state.productProfile.detailsofOwner
        ? state.productProfile.detailsofOwner
        : "";
      formatProductProfile.description = state.productProfile.description
        ? state.productProfile.description
        : "";
      formatProductProfile.facebook = state.productProfile.socialMedia.facebook
        ? state.productProfile.socialMedia.facebook
        : "";
      formatProductProfile.linkedIn = state.productProfile.socialMedia.linkedIn
        ? state.productProfile.socialMedia.linkedIn
        : "";
      formatProductProfile.twitter = state.productProfile.socialMedia.twitter
        ? state.productProfile.socialMedia.twitter
        : "";
      formatProductProfile.otherURLS = state.productProfile.socialMedia
        .otherURLS
        ? state.productProfile.socialMedia.otherURLS
        : "";

      formatProductProfile.blockchainExchange = {
        value:
          state.productProfile.blockchainExchange == "true" ? "true" : "false",
        text: state.productProfile.blockchainExchange == "true" ? "Yes" : "No",
      };

      formatProductProfile.productExpiryDate = state.productProfile.product
        .productExpiryDate
        ? state.productProfile.product.productExpiryDate
        : "";
      formatProductProfile.productRegistration = state.productProfile.product
        .productRegistration
        ? state.productProfile.product.productRegistration
        : "";
      formatProductProfile.productQuantity = state.productProfile.product
        .productQuantity
        ? state.productProfile.product.productQuantity
        : "";
      formatProductProfile.productSales = state.productProfile.product
        .productSales
        ? state.productProfile.product.productSales
        : "";

      if (state.productProfile.saleType) {
        if (state.productProfile.saleType == "1") {
          formatProductProfile.saleType = {
            value: state.productProfile.saleType,
            text: "My Own Price",
          };
        } else if (state.productProfile.saleType == "2") {
          formatProductProfile.saleType = {
            value: state.productProfile.saleType,
            text: "Open Auction",
          };
        } else if (state.productProfile.saleType == "3") {
          formatProductProfile.saleType = {
            value: state.productProfile.saleType,
            text: "Timed Auction",
          };
        } else {
          formatProductProfile.saleType = {
            value: "",
            text: "",
          };
        }
      }

      formatProductProfile.priceNumber = state.productProfile.price.priceNumber
        ? state.productProfile.price.priceNumber
        : "";

      formatProductProfile.currencyId = {
        value: state.productProfile.price.currencyId._id,
        text: state.productProfile.price.currencyId.text,
      };

      const profileIndustriesArray = [];
      state.productProfile.profileIndustries.forEach(
        (profileIndustries, index) => {
          profileIndustriesArray.push(profileIndustries._id);
          // console.log(value);
          // console.log(index);
        }
      );
      formatProductProfile.profileIndustries = state.productProfile
        .profileIndustries
        ? profileIndustriesArray
        : [];

      return formatProductProfile;
    },

    getProjectProfile(state) {
      const formatProjectProfile = {};

      formatProjectProfile._id = state.projectProfile._id
        ? state.projectProfile._id
        : "";
      formatProjectProfile.name = state.projectProfile.name
        ? state.projectProfile.name
        : "";

      formatProjectProfile.typeofOwner = {
        value:
          state.projectProfile.typeofOwner == "individual"
            ? "individual"
            : "group",
        text:
          state.projectProfile.typeofOwner == "individual"
            ? "Individual"
            : "Group",
      };

      if (state.projectProfile.address.profileCountry._id) {
        formatProjectProfile.profileCountry = {
          value: state.projectProfile.address.profileCountry._id,
          text: state.projectProfile.address.profileCountry.text,
        };
      } else {
        formatProjectProfile.profileCountry = {
          value: "",
          text: "",
        };
      }

      formatProjectProfile.profileState = {
        value: state.projectProfile.address.profileState,
        text: "",
      };
      formatProjectProfile.profileCity = state.projectProfile.address
        .profileCity
        ? state.projectProfile.address.profileCity
        : "";

      formatProjectProfile.category = {
        value: state.projectProfile.category.value,
        text: state.projectProfile.category.text,
      };

      formatProjectProfile.highlights = state.projectProfile.highlights
        ? state.projectProfile.highlights
        : "";
      formatProjectProfile.detailsofOwner = state.projectProfile.detailsofOwner
        ? state.projectProfile.detailsofOwner
        : "";
      formatProjectProfile.description = state.projectProfile.description
        ? state.projectProfile.description
        : "";
      formatProjectProfile.assetsType = state.projectProfile.project.assetsType
        ? state.projectProfile.project.assetsType
        : "";
      formatProjectProfile.staffNumbers = state.projectProfile.project
        .staffNumbers
        ? state.projectProfile.project.staffNumbers
        : "";

      formatProjectProfile.projectTime = {
        value: state.projectProfile.project.projectTime == "1" ? "1" : "2",
        text:
          state.projectProfile.project.projectTime == "1"
            ? "Open"
            : "Scheduled",
      };

      formatProjectProfile.startAt = state.projectProfile.project.startAt
        ? state.projectProfile.project.startAt
        : "";
      formatProjectProfile.endAt = state.projectProfile.project.endAt
        ? state.projectProfile.project.endAt
        : "";

      formatProjectProfile.budgetAmount = state.projectProfile.project
        .budgetAmount
        ? state.projectProfile.project.budgetAmount
        : "";
      formatProjectProfile.budgetCurrencyId = {
        value: state.projectProfile.project.budgetCurrencyId._id,
        text: state.projectProfile.project.budgetCurrencyId.text,
      };

      if (state.projectProfile.saleType) {
        if (state.projectProfile.saleType == "1") {
          formatProjectProfile.saleType = {
            value: state.projectProfile.saleType,
            text: "My Own Price",
          };
        } else if (state.projectProfile.saleType == "2") {
          formatProjectProfile.saleType = {
            value: state.projectProfile.saleType,
            text: "Open Auction",
          };
        } else if (state.projectProfile.saleType == "3") {
          formatProjectProfile.saleType = {
            value: state.projectProfile.saleType,
            text: "Timed Auction",
          };
        } else {
          formatProjectProfile.saleType = {
            value: "",
            text: "",
          };
        }
      }

      formatProjectProfile.priceNumber = state.projectProfile.price.priceNumber
        ? state.projectProfile.price.priceNumber
        : "";

      formatProjectProfile.currencyId = {
        value: state.projectProfile.price.currencyId._id,
        text: state.projectProfile.price.currencyId.text,
      };

      formatProjectProfile.blockchainExchange = {
        value:
          state.projectProfile.blockchainExchange == "true" ? "true" : "false",
        text: state.projectProfile.blockchainExchange == "true" ? "Yes" : "No",
      };
      formatProjectProfile.facebook = state.projectProfile.socialMedia.facebook
        ? state.projectProfile.socialMedia.facebook
        : "";
      formatProjectProfile.linkedIn = state.projectProfile.socialMedia.linkedIn
        ? state.projectProfile.socialMedia.linkedIn
        : "";
      formatProjectProfile.twitter = state.projectProfile.socialMedia.twitter
        ? state.projectProfile.socialMedia.twitter
        : "";
      formatProjectProfile.otherURLS = state.projectProfile.socialMedia
        .otherURLS
        ? state.projectProfile.socialMedia.otherURLS
        : "";

      const profileIndustriesArray = [];
      state.projectProfile.profileIndustries.forEach(
        (profileIndustries, index) => {
          profileIndustriesArray.push(profileIndustries._id);
          // console.log(value);
          // console.log(index);
        }
      );
      formatProjectProfile.profileIndustries = state.projectProfile
        .profileIndustries
        ? profileIndustriesArray
        : [];

      // return formatProductProfile;

      return formatProjectProfile;
    },

    getIdeaProfile(state) {
      const formatIdeaProfile = {};
      formatIdeaProfile._id = state.ideaProfile._id
        ? state.ideaProfile._id
        : "";
      formatIdeaProfile.name = state.ideaProfile.name
        ? state.ideaProfile.name
        : "";
      formatIdeaProfile.typeofOwner = {
        value:
          state.ideaProfile.typeofOwner == "individual"
            ? "individual"
            : "group",
        text:
          state.ideaProfile.typeofOwner == "individual"
            ? "Individual"
            : "Group",
      };

      if (state.ideaProfile.address.profileCountry._id) {
        formatIdeaProfile.profileCountry = {
          value: state.ideaProfile.address.profileCountry._id,
          text: state.ideaProfile.address.profileCountry.text,
        };
      } else {
        formatIdeaProfile.profileCountry = {
          value: "",
          text: "",
        };
      }

      formatIdeaProfile.profileState = {
        value: state.ideaProfile.address.profileState,
        text: "",
      };
      formatIdeaProfile.profileCity = state.ideaProfile.address.profileCity
        ? state.ideaProfile.address.profileCity
        : "";

      formatIdeaProfile.highlights = state.ideaProfile.highlights
        ? state.ideaProfile.highlights
        : "";
      formatIdeaProfile.detailsofOwner = state.ideaProfile.detailsofOwner
        ? state.ideaProfile.detailsofOwner
        : "";
      formatIdeaProfile.description = state.ideaProfile.description
        ? state.ideaProfile.description
        : "";
      formatIdeaProfile.ideaReference = state.ideaProfile.idea.ideaReference
        ? state.ideaProfile.idea.ideaReference
        : "";
      formatIdeaProfile.category = {
        value: state.ideaProfile.category._id,
        text: state.ideaProfile.category.text,
      };
      if (state.ideaProfile.saleType) {
        if (state.ideaProfile.saleType == "1") {
          formatIdeaProfile.saleType = {
            value: state.ideaProfile.saleType,
            text: "My Own Price",
          };
        } else if (state.ideaProfile.saleType == "2") {
          formatIdeaProfile.saleType = {
            value: state.ideaProfile.saleType,
            text: "Open Auction",
          };
        } else if (state.ideaProfile.saleType == "3") {
          formatIdeaProfile.saleType = {
            value: state.ideaProfile.saleType,
            text: "Timed Auction",
          };
        } else {
          formatIdeaProfile.saleType = {
            value: "",
            text: "",
          };
        }
      }

      formatIdeaProfile.priceNumber = state.ideaProfile.price.priceNumber
        ? state.ideaProfile.price.priceNumber
        : "";

      formatIdeaProfile.currencyId = {
        value: state.ideaProfile.price.currencyId._id,
        text: state.ideaProfile.price.currencyId.text,
      };
      formatIdeaProfile.ideaStatus = {
        value: state.ideaProfile.idea.ideaStatus == "1" ? "1" : "2",
        text:
          state.ideaProfile.idea.ideaStatus == "1" ? "Complete" : "Incomplete",
      };
      formatIdeaProfile.blockchainExchange = {
        value:
          state.ideaProfile.blockchainExchange == "true" ? "true" : "false",
        text: state.ideaProfile.blockchainExchange == "true" ? "Yes" : "No",
      };

      formatIdeaProfile.facebook = state.ideaProfile.socialMedia.facebook
        ? state.ideaProfile.socialMedia.facebook
        : "";
      formatIdeaProfile.linkedIn = state.ideaProfile.socialMedia.linkedIn
        ? state.ideaProfile.socialMedia.linkedIn
        : "";
      formatIdeaProfile.twitter = state.ideaProfile.socialMedia.twitter
        ? state.ideaProfile.socialMedia.twitter
        : "";
      formatIdeaProfile.otherURLS = state.ideaProfile.socialMedia.otherURLS
        ? state.ideaProfile.socialMedia.otherURLS
        : "";

      const profileIndustriesArray = [];
      state.ideaProfile.profileIndustries.forEach(
        (profileIndustries, index) => {
          profileIndustriesArray.push(profileIndustries._id);
          // console.log(value);
          // console.log(index);
        }
      );
      formatIdeaProfile.profileIndustries = state.ideaProfile.profileIndustries
        ? profileIndustriesArray
        : [];

      return formatIdeaProfile;
    },
    getServiceDetails(state) {
      return state.serviceProfile;
    },
    getAllPermissions(state) {
      return state.permissions;
    },
    getAllRoles(state) {
      return state.roles;
    },
    getAllPlans(state) {
      return state.plans;
    },
    getStatistics(state) {
      return state.statistics;
    },
    goalOverview(state) {
      const goalOverview = {
        completed: "786,617",
        inProgress: "13,561",
        series: [83],
      };
      goalOverview.completed = state.statistics.totalClosed;
      goalOverview.inProgress = state.statistics.totalCreated;
      goalOverview.series = [
        (state.statistics.totalClosed /
          (state.statistics.totalCreated + state.statistics.totalClosed)) *
          100,
      ];
      return goalOverview;
    },
    getCompanyStatisticsItems(state) {
      const goalOverview = [
        {
          avatarImg: require("@/assets/images/icons/toolbox.svg"),
          title: "Companies",
          viewTitle: state.statistics.usersData.totalApprovedCompanies,
          viewsub: state.statistics.usersData.totalUnapprovedCompanies,
          revenue: state.statistics.usersData.totalCompanies,
          sales:
            (state.statistics.usersData.totalCompanies /
              state.statistics.usersData.totalApprovedCompanies) *
            100,
          loss: false,
        },
        {
          avatarImg: require("@/assets/images/icons/parachute.svg"),
          title: "Investors",
          viewTitle: state.statistics.usersData.totalApprovedInvestors,
          viewsub: state.statistics.usersData.totalUnapprovedInvestors,
          revenue: state.statistics.usersData.totalInvestors,
          sales:
            (state.statistics.usersData.totalInvestors /
              state.statistics.usersData.totalApprovedInvestors) *
            100,
          loss: false,
        },
        {
          avatarImg: require("@/assets/images/icons/brush.svg"),
          title: "Freelancers",
          viewTitle: state.statistics.usersData.totalApprovedFreelancers,
          viewsub: state.statistics.usersData.totalUnapprovedFreelancers,
          revenue: state.statistics.usersData.totalFreelancers,
          sales:
            (state.statistics.usersData.totalFreelancers /
              state.statistics.usersData.totalApprovedFreelancers) *
            100,
          loss: false,
        },
        {
          avatarImg: require("@/assets/images/icons/star.svg"),
          title: "Brokers",
          viewTitle: state.statistics.usersData.totalApprovedBrokers,
          viewsub: state.statistics.usersData.totalUnapprovedBrokers,
          revenue: state.statistics.usersData.totalBrokers,
          sales:
            (state.statistics.usersData.totalBrokers /
              state.statistics.usersData.totalApprovedBrokers) *
            100,
          loss: false,
        },
        {
          avatarImg: require("@/assets/images/icons/book.svg"),
          title: "Advisors",
          viewTitle: state.statistics.usersData.totalApprovedAdvisors,
          viewsub: state.statistics.usersData.totalUnapprovedAdvisors,
          revenue: state.statistics.usersData.totalAdvisors,
          sales:
            (state.statistics.usersData.totalAdvisors /
              state.statistics.usersData.totalApprovedAdvisors) *
            100,
          loss: false,
        },
        {
          avatarImg: require("@/assets/images/icons/rocket.svg"),
          title: "Partners",
          viewTitle: state.statistics.usersData.totalApprovedPartners,
          viewsub: state.statistics.usersData.totalUnapprovedPartners,
          revenue: state.statistics.usersData.totalPartners,
          sales:
            (state.statistics.usersData.totalPartners /
              state.statistics.usersData.totalApprovedPartners) *
            100,
          loss: false,
        },
      ];
      return goalOverview;
    },
    getCurrentVerifyEmail(state) {
      return state.currentVerifyEmail
        ? state.currentVerifyEmail
        : localStorage.getItem("verifyEmail");
    },
    getCountryCode(state) {
      return state.countryCode;
    },
  },
};
