/* eslint-disable no-return-await */
import axios from 'axios'

import partner from '../../navigation/vertical/partner'
import superAdmin from '../../navigation/vertical/super-admin'
import userNav from '../../navigation/vertical/user'
import employee from '../../navigation/vertical/employee'

export default {
  namespaced: true,
  state: {
    user: null,
    roles: null,
    accessToken: null,
    isAuth: false,
    unreadNotificationsCount: 0,
    unreadMessagesCount: 0,
    notifications: [],
  },
  getters: {
    leftSideItems(state) {
      if (state.isAuth) {
        if (state.roles === 'Partner') {
          return partner
        } if (state.roles === 'Super-admin') {
          return superAdmin
        }
        if (state.roles === 'Employee') {
          return employee
        }
        if (state.roles === 'User') {
          if (state.user.userType === 'freelancer' || state.user.userType === 'investor' || state.user.userType === 'company') {
            return userNav
          }
          if (state.user.userType === 'broker' || state.user.userType === 'advisor') {
            return [

              { header: ('dashboard.Navgation') },
              {
                title: ('dashboard.BusinessLounge'),
                route: 'timeline',
                icon: 'BookmarkIcon',
              },
              {
                title: ('dashboard.Services'),
                icon: 'StarIcon',
                children: [
                  {
                    title: ('dashboard.Create'),
                    route: 'service-create',
                  },
                  {
                    title: ('dashboard.ListAll'),
                    route: 'service-listAll',
                  },
                ],
              },
              {
                title: 'Support Tickets',
                icon: 'HelpCircleIcon',
                children: [
                  {
                    title: 'Support Tickets',
                    route: 'SupportTickets',
                  },
                ],
              },
              {
                title: ('dashboard.MySubscriptions'),
                icon: 'AwardIcon',
                route: 'my-subscriptions',
              },
              {
                title: ('dashboard.Setting'),
                icon: 'SettingsIcon',
                route: 'pages-account-setting',
              },
            ]
          }
        }
      }
      return []
    },
    isAuth(state) {
      return state.isAuth
    },
    getUserType(state) {
      if (state.isAuth) {
        const userRole = state.roles
        if (userRole === 'Partner' || userRole === 'User') {
          return true
        }
      }
      return false
    },
    getProfileUserType(state) {
      if (state.isAuth) {
        return state.user.userType
      }
      return null
    },
    getAccessToken(state) {
      return state.accessToken
    },
    getTrialCount(state) {
      if (state.isAuth) {
        return state.user.trialCount
      }
      return null
    },
    getTrialExpirationDate(state) {
      if (state.isAuth) {
        return state.user.trialExpirationDate
      }
      return null
    },
    getRole(state) {
      if (state.isAuth) {
        return state.roles
      }
      return null
    },
    authenticated(state) {
      return state.accessToken && state.user
    },
    user(state) {
      return state.user
    },
    getNotifications(state) {
      return state.notifications
    },
    getUnreadNotificationsCount(state) {
      return state.unreadNotificationsCount
    },
    getUnreadMessagesCount(state) {
      return state.unreadMessagesCount
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },
    SET_IS_AUTH(state, isAuth) {
      state.isAuth = isAuth
    },
    SET_ROLES(state, roles) {
      state.roles = roles
    },
    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken
      localStorage.setItem('accessToken', accessToken)
    },
    SET_UNREAD_NOIFICATIONS_COUNT(state, count) {
      state.unreadNotificationsCount = count
    },
    SET_UNREAD_MESSAGES_COUNT(state, count) {
      state.unreadMessagesCount = count
    },
  },
  actions: {
    updateUnreadNotificationsCount({ commit, state }, newCount) {
      commit('SET_UNREAD_NOIFICATIONS_COUNT', newCount)
    },
    addNewNotification({ commit, state, dispatch }, notification) {
      /* const tempNotifications = state.notifications;
      tempNotifications.unshift(notification);
      commit("SET_NOTIFICATIONS", tempNotifications);
      console.log(state.notifications); */
      state.notifications.unshift(notification)
      commit('SET_NOTIFICATIONS', state.notifications)
    },
    async register(_, data) {
      return await axios.post('auth/register', data)
    },
    async resendEmailToken(_, data) {
      return await axios.post('auth/resendToken', data)
    },
    async resetPassword(_, data) {
      return await axios.post('auth/recover', data)
    },
    async resetPasswordToken(_, data) {
      return await axios.post('reset/:token', data)
    },
    async socialRegister({ dispatch }, data) {
      try {
        const response = await axios.post('auth/social/login', data)
        return dispatch('attempt', response.data.accessToken)
      } catch (e) {
        console.error(e)
      }
    },
    async login({ dispatch }, data) {
      try {
        const response = await axios.post('auth/login', data)
        return dispatch('attempt', response.data.access_token)
      } catch (e) {
        return error
      }
    },
    async attempt({ commit, state }, token) {
      if (token) {
        await commit('SET_ACCESS_TOKEN', token)
      }
      if (!state.accessToken) {
        return
      }
      try {
        const response = await axios.get('auth/user')
        commit('SET_USER', await response.data)
        commit('SET_IS_AUTH', await true)
        commit('SET_ROLES', (response.data.role.name) === undefined ? 'Guest' : response.data.role.name)
        commit('SET_NOTIFICATIONS', response.data.notifications)
        commit('SET_UNREAD_NOIFICATIONS_COUNT', response.data.unreadNotifications)
        commit('SET_UNREAD_MESSAGES_COUNT', response.data.unreadChats)
        commit('SET_USER', await response.data)
        commit('SET_IS_AUTH', await true)
        commit(
          'SET_ROLES',
          (await response.data.role.name) === undefined
            ? 'Guest'
            : response.data.role.name,
        )
        commit('SET_NOTIFICATIONS', response.data.notifications)
        commit(
          'SET_UNREAD_NOIFICATIONS_COUNT',
          response.data.unreadNotifications,
        )
        commit('SET_UNREAD_MESSAGES_COUNT', response.data.unreadChats)

        // localStorage.setItem('userData', response.data)
      } catch (e) {
        commit('SET_ACCESS_TOKEN', null)
        commit('SET_USER', null)
        commit('SET_NOTIFICATIONS', [])
        commit('SET_ROLES', null)
        commit('SET_IS_AUTH', false)
        commit('SET_UNREAD_NOIFICATIONS_COUNT', 0)
        commit('SET_UNREAD_MESSAGES_COUNT', 0)
      }
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          commit('SET_ACCESS_TOKEN', null)
          commit('SET_USER', null)
          commit('SET_NOTIFICATIONS', [])
          commit('SET_ROLES', null)
          commit('SET_IS_AUTH', false)
          localStorage.removeItem('accessToken')
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    async editPassword(_, data) {
      return axios.post('auth/changePassword', data)
    },
    async verifyEmail(_, data) {
      return axios.get(`auth/verify/${data}`)
    },
  },
  modules: {},
}
