import axios from "axios";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    countries: [],
    states: [],
  },
  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_STATES(state, states) {
      state.states = states;
    },
  },
  actions: {
    async retreiveCountries({ commit }) {
      const headers = new FormData();
      headers.append("perPage", 250);
      // eslint-disable-next-line no-return-await
      return await axios.post("countries/search", headers).then((response) => {
        commit("SET_COUNTRIES", response.data.data);
      });
    },
    async retreiveStates({ commit }, data) {
      // eslint-disable-next-line no-return-await
      return await axios.get(`countries/${data}`).then((response) => {
        commit("SET_STATES", response.data.data.states);
      });
    },
  },
  getters: {
    getCountries(state) {
      const formatCountries = [];
      state.countries.forEach((element) => {
        formatCountries.push({
          // eslint-disable-next-line no-underscore-dangle
          value: element._id,
          text:
            store.state.locale.locale == "ar"
              ? element.arabicName
              : element.name,
        });
      });
      return formatCountries;
    },
    getFilterCountries(state) {
      const formatCountries = [];
      state.countries.forEach((element) => {
        formatCountries.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          label:
            store.state.locale.locale == "ar"
              ? element.arabicName
              : element.name,
        });
      });
      return formatCountries;
    },
    getCountriesCurrency(state) {
      const formatCountries = [];
      state.countries.forEach((element) => {
        formatCountries.push({
          // eslint-disable-next-line no-underscore-dangle
          value: element.currency,
          label: `${element.name} ${element.currency.toLowerCase()} ${
            element.currencySymbol
          }`,
        });
      });
      return formatCountries;
    },
    getAllStates(state) {
      const formatStates = [];
      state.states.forEach((element) => {
        formatStates.push({
          // eslint-disable-next-line no-underscore-dangle
          value: element.id,
          text:
            store.state.locale.locale == "ar"
              ? element.arabicName != undefined
                ? element.arabicName
                : element.name
              : element.name,
        });
      });
      if (formatStates.length < 1) {
        formatStates.push({
          value: "0",
          text: "no state",
        });
      }
      return formatStates;
    },
  },
};
