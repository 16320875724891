var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"freelancer-form"},[_c('Header',{}),_c('h2',{staticClass:"margin-top25"}),_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('form-wizard',{staticClass:"mb-3 pt-1",attrs:{"color":"#366ce3","title":_vm.$t('FreelancerForm.Register_freelancer'),"subtitle":_vm.$t('FreelancerForm.Complete_your_account'),"shape":"square","finish-button-text":_vm.$t('common.Submit'),"next-button-text":_vm.$t('common.Next'),"back-button-text":_vm.$t('common.Previous')},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":_vm.$t('FreelancerForm.Freelancer_Details'),"icon":"feather icon-file-text","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("FreelancerForm.Freelancer_Details"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("FreelancerForm.Enter_Freelancer_Details"))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Full_Name'),"label-for":"Fomraml_name"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Fomraml_name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('FreelancerForm.Full_Name')},model:{value:(_vm.createFreelancerProfile.name),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "name", $$v)},expression:"createFreelancerProfile.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"john.doe@email.com"},model:{value:(_vm.createFreelancerProfile.emailValue),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "emailValue", $$v)},expression:"createFreelancerProfile.emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Short_Name'),"label-for":"slugan"}},[_c('validation-provider',{attrs:{"name":"slugan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slugan","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('FreelancerForm.Short_Name')},model:{value:(_vm.createFreelancerProfile.slugan),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "slugan", $$v)},expression:"createFreelancerProfile.slugan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Phone_Number'),"label-for":"Phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"}},[_c('VuePhoneNumberInput',{attrs:{"color":"#ea5455","error-color":"#ea5455","translations":_vm.$store.state.locale.locale == 'ar'
                        ? _vm.translationsArabic
                        : _vm.translations},on:{"update":_vm.onUpdate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),(!_vm.isValidNumber)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("common.phone_Invalid")))]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.PassportID'),"label-for":"Passport-ID"}},[_c('validation-provider',{attrs:{"name":"Passport ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Passport-ID","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('FreelancerForm.PassportID')},model:{value:(_vm.createFreelancerProfile.PassportID),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "PassportID", $$v)},expression:"createFreelancerProfile.PassportID"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Gender'),"label-for":"Gender","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Gender","options":_vm.$store.state.locale.locale == 'ar'
                        ? _vm.createFreelancerProfile.GenderOptionArabic
                        : _vm.createFreelancerProfile.GenderOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createFreelancerProfile.selectedGender),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "selectedGender", $$v)},expression:"createFreelancerProfile.selectedGender"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('FreelancerForm.Freelancer_Info'),"icon":"feather icon-user","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Freelancer_Info")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Enter_Freelancer_Info")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Your_nationality'),"label-for":"Nationality","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Nationality","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createFreelancerProfile.selectedNationality),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "selectedNationality", $$v)},expression:"createFreelancerProfile.selectedNationality"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Industry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.industries_belong_to'),"label-for":"Industry","state":errors.length > 0 ? false : null}},[_c('div',{attrs:{"id":"app"}},[_c('treeselect',{attrs:{"multiple":true,"placeholder":_vm.$t('common.Select'),"options":_vm.industries,"normalizer":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.normalizerArabic
                          : _vm.normalizer},on:{"input":_vm.handeTreeSelect},model:{value:(_vm.createFreelancerProfile.selectedIndustry),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "selectedIndustry", $$v)},expression:"createFreelancerProfile.selectedIndustry"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Your_country'),"label-for":"Country","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Country","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},on:{"input":function($event){return _vm.retreiveStates(
                        _vm.createFreelancerProfile.selectedCountry.value
                      )}},model:{value:(_vm.createFreelancerProfile.selectedCountry),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "selectedCountry", $$v)},expression:"createFreelancerProfile.selectedCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Your_state'),"label-for":"State","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"State","options":_vm.statesOptions,"label":"text"},model:{value:(_vm.createFreelancerProfile.selectedState),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "selectedState", $$v)},expression:"createFreelancerProfile.selectedState"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"SkiSkillsIDlls","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Your_skills'),"label-for":"SkillsID","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"SkillsID","options":_vm.skills,"selectable":function () { return _vm.createFreelancerProfile.selectedSkills.length <
                        ("" + _vm.$maxSkillsUser); },"multiple":"","label":"text"},model:{value:(_vm.createFreelancerProfile.selectedSkills),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "selectedSkills", $$v)},expression:"createFreelancerProfile.selectedSkills"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Brief_overview_yourself'),"label-for":"Highlights"}},[_c('validation-provider',{attrs:{"name":"Highlights","rules":("required|max:" + _vm.$maxHighlightsUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Highlights","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('FreelancerForm.Brief_overview_yourself')},model:{value:(_vm.createFreelancerProfile.Highlights),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "Highlights", $$v)},expression:"createFreelancerProfile.Highlights"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.description_yourself'),"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('FreelancerForm.description_yourself')},model:{value:(_vm.createFreelancerProfile.description),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "description", $$v)},expression:"createFreelancerProfile.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('FreelancerForm.Freelancer_Experience'),"icon":"feather icon-tag","before-change":_vm.validationFormExperience}},[_c('validation-observer',{ref:"ExperienceRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("FreelancerForm.About_you")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Enter_Your_Experience")))]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"","label-for":"language"}},[_c('validation-provider',{attrs:{"name":"language","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('form-repeater-basic',{attrs:{"items":_vm.items}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"","label-for":"certification"}},[_c('validation-provider',{attrs:{"name":"certification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('certificate',{attrs:{"certificates":_vm.certificates}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"","label-for":"certification"}},[_c('validation-provider',{attrs:{"name":"certification","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('Institute',{attrs:{"institute":_vm.institute}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"","label-for":"Experience"}},[_c('validation-provider',{attrs:{"name":"Experience","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('Experience',{attrs:{"experiences":_vm.experiences}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('FreelancerForm.Social_Links'),"icon":"feather icon-link","before-change":_vm.validationFormSocial}},[_c('validation-observer',{ref:"socialRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Social_Links")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Enter_Social_Links")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Facebook'),"label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.createFreelancerProfile.facebookUrl),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "facebookUrl", $$v)},expression:"createFreelancerProfile.facebookUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Twitter'),"label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"placeholder":"https://twitter.com/abc"},model:{value:(_vm.createFreelancerProfile.twitterUrl),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "twitterUrl", $$v)},expression:"createFreelancerProfile.twitterUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.LinkedIn'),"label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false : null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.createFreelancerProfile.linkedinUrl),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "linkedinUrl", $$v)},expression:"createFreelancerProfile.linkedinUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FreelancerForm.Website'),"label-for":"Other"}},[_c('validation-provider',{attrs:{"name":"Other","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Other","state":errors.length > 0 ? false : null,"placeholder":"https://other.com/abc"},model:{value:(_vm.createFreelancerProfile.OtherUrl),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "OtherUrl", $$v)},expression:"createFreelancerProfile.OtherUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('FreelancerForm.Attachment'),"icon":"feather icon-file-plus","before-change":_vm.validationFormFiles}},[_c('validation-observer',{ref:"attachmentRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Attachment")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Upload_your_files"))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Personal Photo","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{attrs:{"for":"PersonalPhoto"}},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Personal_Photo"))+" ")]),_c('b-form-file',{attrs:{"id":"PersonalPhoto","required":"","accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","multiple":false,"browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createFreelancerProfile.PersonalPhoto),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "PersonalPhoto", $$v)},expression:"createFreelancerProfile.PersonalPhoto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Freelancer Prove","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{attrs:{"for":"Freelancer-Prove"}},[_vm._v(_vm._s(_vm.$t("FreelancerForm.Freelancer_Prove"))+" ")]),_c('b-form-file',{attrs:{"id":"Freelancer-Prove","required":"","accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","multiple":false,"browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createFreelancerProfile.FreelancerProve),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "FreelancerProve", $$v)},expression:"createFreelancerProfile.FreelancerProve"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"certificates","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{attrs:{"for":"Freelancer-certificates"}},[_vm._v(_vm._s(_vm.$t("FreelancerForm.your_certificates"))+" ")]),_c('b-form-file',{attrs:{"id":"Freelancer-certificates","accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","multiple":"","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createFreelancerProfile.FreelancerCertificates),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "FreelancerCertificates", $$v)},expression:"createFreelancerProfile.FreelancerCertificates"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"relevant docouments","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{attrs:{"for":"relevant-Docouments"}},[_vm._v(_vm._s(_vm.$t("FreelancerForm.relevant_Docouments"))+" ")]),_c('b-form-file',{attrs:{"id":"relevant-Docouments","accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","multiple":"","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createFreelancerProfile.FreelancerRelevant),callback:function ($$v) {_vm.$set(_vm.createFreelancerProfile, "FreelancerRelevant", $$v)},expression:"createFreelancerProfile.FreelancerRelevant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[(_vm.errorMessage)?_c('b-alert',{staticClass:"warning p-1",staticStyle:{"color":"red"},attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)],1)],1)],1)],1)],1),_c('Footer',{staticClass:"company-form-footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }