export default function createServiceProfile({ next, store }) {
  console.log("store.getters.getProfileUserType=>", store.getters["auth/getProfileUserType"]);
  const status = store.getters["auth/getProfileUserType"];
  if (
    status === "freelancer" ||
    status === "investor" ||
    status === "company" ||
    status === "advisor" ||
    status === "broker"
  ) {
    return next();
  }
  return next({ name: "misc-not-authorized" });
}
