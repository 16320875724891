export default [
  {
    header: 'Super Admin',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Verifications',
    route: 'verifications',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'Profiles',
    icon: 'UserIcon',
    children: [
      {
        title: 'Ideas',
        route: 'ideas',
      },
      {
        title: 'Projects',
        route: 'projects',
      },
      {
        title: 'Products',
        route: 'products',
      },
      {
        title: 'Services',
        route: 'services',
      },
    ],
  },
  {
    title: 'Users Management',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Support Tickets',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: 'Support Tickets',
        route: 'SupportTickets',
      },
      {
        title: 'Departments',
        route: 'SupportDepartments',
      },
      {
        title: 'Settings',
        route: 'SupportSettings',
      },
    ],
  },
  {
    title: 'Users Activity',
    route: 'users-activity',
    icon: 'ActivityIcon',
  },
  {
    title: 'Plans & Subscriptions',
    icon: 'BoxIcon',
    children: [
      {
        title: 'List All Plans',
        route: 'plans',
      },
    ],
  },
  {
    title: 'Industries',
    route: 'industries',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Categories',
    icon: 'ListIcon',
    route: 'categories',
  },
  {
    title: 'Skills',
    icon: 'AwardIcon',
    route: 'skills',
  },
  {
    title: 'Partners',
    route: 'partners',
    icon: 'UsersIcon',
  },
  {
    title: 'Blogs',
    route: 'blogs',
    icon: 'BookIcon',
  },
  {
    title: 'FAQ',
    route: 'faq-list',
    icon: 'HelpCircleIcon',
  },
  {
    title: 'Currencies',
    route: 'currencies',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Complaints',
    icon: 'AlertTriangleIcon',
    children: [
      {
        title: 'Manage Complaints',
        route: 'complaints',
      },
      {
        title: 'Reasons',
        route: 'complainsReasons',
      },
    ],
  },
  {
    title: 'Mail Notifications',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Mail Notification',
        route: 'mailNotifications',
      },
    ],
  },
  {
    title: 'Contact Messages',
    icon: 'MailIcon',
    children: [
      {
        title: 'Contact Messages',
        route: 'apps-email',
      },
    ],
  },
  {
    title: 'Payment Gateway',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Subscription',
        route: 'subscription',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'General Settings',
      },
      {
        title: 'Email Tempaltes',
      },
      {
        title: 'Countries',
        route: 'countries',
      },
    ],
  },
]
