import Vue from 'vue'
import VueRouter from 'vue-router'
import AccountType from '@/views/pages/account-type/AccontType4.vue'
import store from '../store'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import nftdubai from './routes/nftdubai'
import middlewarePipeline from './routes/middle-wares/middlewarePipeline'
import guestMiddleware from './routes/middle-wares/dashboard/guestMiddleware'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', name: { name: 'index' }, redirect: '/index' },
    { path: '/dashboard', redirect: { name: 'dashboard' } },
    {
      path: '/account-type',
      name: 'account-type',
      component: AccountType,
      meta: {
        layout: 'full',
        middleware: [
          guestMiddleware,
        ],
      },
    },
    ...nftdubai,
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters.isAuth) {
//       next()
//       return
//     }
//     next('/login')
//   } else {
//     next()
//   }
// })
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const { middleware } = to.meta

  const context = {
    to,
    from,
    next,
    store,
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()
//
//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })
//
//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }
//
//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }
//
//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
