export default {
  namespaced: true,
  state: {
    myCurrency: JSON.parse(localStorage.getItem("currency"))
      ? JSON.parse(localStorage.getItem("currency"))
      : { name: "US Dollar", symbol: "$" },
    // myCurrency: {
    //   _id: "629f4f8a3715037845a8c6a8",
    //   name: "US Dollar",
    //   shortName: "USD",
    //   rate: "1",
    //   symbol: "$",
    //   symbolDirection: "left",
    //   isActive: true,
    //   id: "629f4f8a3715037845a8c6a8",
    //   value: "629f4f8a3715037845a8c6a8",
    //   text: "US Dollar",
    // },
  },
  mutations: {
    SET_MY_CURRENCY(state, myCurrency) {
      state.myCurrency = myCurrency;
    },
  },
  actions: {
    // retreiveMyCurrency({ commit }, data) {
    //   // eslint-disable-next-line no-return-await
    //   commit("SET_MY_CURRENCY", data);
    //   console.log("retreiveMyCurrency");
    // },
    changeMyCurrency({ commit }, data) {
      // eslint-disable-next-line no-return-await
      //   console.log("changeMyCurrency");
      commit("SET_MY_CURRENCY", data);
    },
  },
  getters: {
    getMyCurrency(state) {
      //   console.log("sdsd");
      return state.myCurrency;
    },
  },
};
