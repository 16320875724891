<template>
  <div>
    <Header class="header-companylist" />
    <div>
      <!-- search input -->
      <section id="knowledge-base-search">
        <div class="accounttype breadcrumbs d-flex align-items-center">
          <div
            class="
              container
              position-relative
              d-flex
              flex-column
              align-items-center
            "
          >
            <h2 class="">
              {{ $t("accountType.Create_your_account") }}
            </h2>

            <!-- form -->
            <!-- <b-form class="kb-search-input">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="searchbar"
                  class="search-input"
                  v-model="knowledgeBaseSearchQuery"
                  :placeholder="$t('accountType.Search_type')"
                />
              </b-input-group>
            </b-form> -->
            <!-- form -->
          </div>
        </div>
      </section>
      <!--/ search input -->

      <section id="knowledge-base-content">
        <b-container>
          <!-- content -->
          <b-row class="kb-search-content-info match-height">
            <b-col
              v-for="item in filteredKB"
              :key="item.id"
              md="4"
              sm="6"
              class="kb-search-content"
            >
              <b-card
                class="text-center cursor-pointer"
                :img-src="item.img"
                :img-alt="item.img.slice(5)"
                img-top
                @click="$router.push({ name: `${item.link}` })"
              >
                <h4>{{ item.title }}</h4>
                <b-card-text class="mt-1">
                  {{ item.desc }}
                </b-card-text>
              </b-card>
            </b-col>
            <b-col v-show="!filteredKB.length" cols="12" class="text-center">
              <!-- <h4 class="mt-4">
              Search result not found!!
            </h4> -->
            </b-col>
          </b-row>
        </b-container>
      </section>
      <Footer class="footer-companyList" />
    </div>
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import store from "@/store";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BContainer,
} from "bootstrap-vue";

export default {
  components: {
    Header,
    Footer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BContainer,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: "",
      kb: [],
      knowledgeBase: [
        {
          id: 1,
          link: "company-create",
          img: require("@/assets/images/illustration/company.jpg"),
          title: this.$i18n.t("accountType.Register_Company"),
          desc: this.$i18n.t("accountType.Register_Company_p"),
        },
        {
          id: 2,
          link: "investor-create",
          img: require("@/assets/images/illustration/investors.jpg"),
          title: this.$i18n.t("accountType.Register_Investor"),
          desc: this.$i18n.t("accountType.Register_Investor_p"),
        },
        {
          id: 3,
          link: "freelancer-create",
          img: require("@/assets/images/illustration/freelancer.jpg"),
          title: this.$i18n.t("accountType.Register_FreeLancer"),
          desc: this.$i18n.t("accountType.Register_FreeLancer_p"),
        },
        {
          id: 4,
          link: "validator-create",
          img: require("@/assets/images/illustration/validator.jpg"),
          title: this.$i18n.t("accountType.Register_Validator"),
          desc: this.$i18n.t("accountType.Register_Validator_p"),
        },
        {
          id: 5,
          link: "advisor-create",
          img: require("@/assets/images/illustration/advisor.jpg"),
          title: this.$i18n.t("accountType.Register_Advisor"),
          desc: this.$i18n.t("accountType.Register_Advisor_p"),
        },
        {
          id: 6,
          link: "broker-create",
          img: require("@/assets/images/illustration/broker.jpg"),
          title: this.$i18n.t("accountType.Register_Broker"),
          desc: this.$i18n.t("accountType.Register_Broker_p"),
        },
      ],
    };
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower =
        this.knowledgeBaseSearchQuery.toLowerCase();
      return this.kb.filter(
        (item) =>
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) ||
          item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower)
      );
    },
  },
  created() {
    this.$http.get("/kb/data/account-type").then((res) => {
      this.kb = res.data;
    });
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";

@media (min-width: 992px) {
  [dir] .knowledge-base-bg .card-body {
    padding: 18px;
  }
}
</style>
<style scoped>
.footer-companyList {
  margin-top: 100px;
}

.card-body h4 {
  color: #2f2d7e;
}
</style>

<style>
.accounttype {
  margin-bottom: 75px;
}
/* .card.faq-search {
  background-repeat: no-repeat;
  background-position: center;
} */

.accounttype {
  background-image: url(../../../assets/images/illustration/cover.jpg);
  padding: 140px 0 60px 0;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs:before {
  content: "";
  background-color: rgba(27, 47, 69, 0.7);
  position: absolute;
  inset: 0;
}
.breadcrumbs h2 {
  font-weight: 500;
  font-size: 30px;
  color: #fff;
}

.kb-search-input {
  width: 38%;
}

@media (max-width: 720px) {
  .kb-search-input {
    width: 60%;
  }
  .breadcrumbs h2 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    font-family: var(--font-secondary);
  }
}

.kb-search-content-info .kb-search-content .card-img-top {
  /* width: 413px; */
  height: 300px;
}

@media (max-width: 720px) {
  .kb-search-input {
    width: 60%;
  }
  .breadcrumbs h2 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    font-family: var(--font-secondary);
  }
  .kb-search-content-info .kb-search-content .card-img-top {
    width: auto;
  }
}
</style>
<style>
[dir].Vue-Toastification__container {
  z-index: 555555;
}
</style>