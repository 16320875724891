<template>
  <div class="company-form">
    <Header class="" />
    <h2 class="margin-top25" />
    <b-overlay :show="show" rounded="sm">
      <form-wizard
        color="#366ce3"
        :title="$t('CompanyForm.Register_Company')"
        :subtitle="$t('CompanyForm.Complete_your_account')"
        shape="square"
        :finish-button-text="$t('common.Submit')"
        :next-button-text="$t('common.Next')"
        :back-button-text="$t('common.Previous')"
        class="mb-3 pt-1"
        @on-complete="formSubmitted"
      >
        <!-- Company details tab      -->
        <tab-content
          :title="$t('CompanyForm.Company_Details')"
          :before-change="validationForm"
          icon="feather icon-file-text"
        >
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("CompanyForm.Company_Details") }}
                </h5>
                <small class="text-muted">
                  {{ $t("CompanyForm.Enter_Company_Details") }}
                </small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Company_name')"
                  label-for="Fomraml_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company name"
                    rules="required"
                  >
                    <b-form-input
                      id="Fomraml_name"
                      v-model="createCompanyProfile.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.Company_name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Company_email')"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="createCompanyProfile.emailValue"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="john.doe@email.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Short_name')"
                  label-for="slugan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="slugan"
                    rules="required"
                  >
                    <b-form-input
                      id="slugan"
                      v-model="createCompanyProfile.slugan"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.Short_name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              {{ myCountry }}
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Phone_Number')"
                  label-for="Phone"
                >
                  <validation-provider name="phone" rules="required">
                    <VuePhoneNumberInput
                      v-model="phoneNumber"
                      color="#ea5455"
                      error-color="#ea5455"
                      :default-country-code="CountryCode"
                      @update="onUpdate"
                      :translations="
                        $store.state.locale.locale == 'ar'
                          ? translationsArabic
                          : translations
                      "
                    />

                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small v-if="!isValidNumber" class="text-danger isValid">{{
                      $t("common.phone_Invalid")
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Company Information tab -->
        <tab-content
          :title="$t('CompanyForm.Company_Info')"
          icon="feather icon-user"
          :before-change="validationFormInfo"
        >
          <validation-observer ref="infoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("CompanyForm.Company_Info") }}
                </h5>
                <small class="text-muted">{{
                  $t("CompanyForm.Enter_Company_Info")
                }}</small>
              </b-col>
              <b-col md="6">
                <label for="example-datepicker"
                  >{{ $t("CompanyForm.Date_of_establishment") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Establishment"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="createCompanyProfile.establishment"
                    :state="errors.length > 0 ? false : null"
                    class="mb-2"
                    :max="max"
                    :locale="$store.state.locale.locale"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="EBITDA"
                    >{{ $t("CompanyForm.EBITDA") }}

                    <!-- <i id="EBITDA-popover" class="fa-solid fa-circle-info" /> -->
                  </label>
                  <!-- <b-popover
                    target="EBITDA-popover"
                    title=""
                    placement="top"
                    triggers="hover focus"
                    :content="$t('companyPopover.EBITDA')"
                  /> -->
                  <validation-provider
                    #default="{ errors }"
                    name="EBITDA"
                    rules="required|between:-100,100"
                  >
                    <b-form-input
                      id="EBITDA"
                      v-model="createCompanyProfile.EBITDA"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.EBITDA')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="Monthly-Sales"
                    >{{ $t("CompanyForm.average_monthly_sales") }}

                    <!-- <i
                      id="MonthlySales-popover"
                      class="fa-solid fa-circle-info"
                    /> -->
                  </label>
                  <!-- <b-popover
                    target="MonthlySales-popover"
                    title=""
                    placement="top"
                    triggers="hover focus"
                    :content="$t('companyPopover.MonthlySales')"
                  /> -->
                  <validation-provider
                    #default="{ errors }"
                    name="Monthly Sales"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="Monthly-Sales"
                      v-model="createCompanyProfile.MonthlySales"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.average_monthly_sales')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="Annual-Sales"
                    >{{ $t("CompanyForm.reported_yearly_sales") }}
                    <!-- <i
                      id="AnnualSales-popover"
                      class="fa-solid fa-circle-info"
                    /> -->
                  </label>
                  <!-- <b-popover
                    target="AnnualSales-popover"
                    title=""
                    placement="top"
                    triggers="hover focus"
                    :content="$t('companyPopover.AnnualSales')"
                  /> -->
                  <validation-provider
                    #default="{ errors }"
                    name="Annual Sales"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="Annual-Sales"
                      v-model="createCompanyProfile.AnnualSales"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.reported_yearly_sales')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('CompanyForm.company_belong_to')"
                    label-for="Industry"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div id="app">
                      <treeselect
                        v-model="createCompanyProfile.industries"
                        :multiple="true"
                        :placeholder="$t('CompanyForm.company_belong_to')"
                        @input="handeTreeSelect"
                        :options="industries"
                        :normalizer="
                          $store.state.locale.locale == 'ar'
                            ? normalizerArabic
                            : normalizer
                        "
                      />
                    </div>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.tangible_and_intangible')"
                  label-for="Assets"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Assets"
                    rules="required"
                  >
                    <b-form-input
                      id="Assets"
                      v-model="createCompanyProfile.Assets"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.tangible_and_intangible')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.physical_assets')"
                  label-for="Physical-Assets"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Physical Assets"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="Physical-Assets"
                      v-model="createCompanyProfile.PhysicalAssets"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.physical_assets')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="categories"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('CompanyForm.interested_in')"
                    label-for="categories"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="categories"
                      v-model="createCompanyProfile.SelectedCategories"
                      :options="categories"
                      :selectable="
                        (option) => !option.value.includes('select_value')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="
                  createCompanyProfile.SelectedCategories.value ===
                  '62a1e60144fbaf36185a39da'
                "
                md="6"
              >
                <b-row>
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Currency"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('CompanyForm.Currency')"
                        label-for=""
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id=""
                          v-model="createCompanyProfile.SelectedCurrency"
                          :options="currencies"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="8">
                    <validation-provider
                      #default="{ errors }"
                      name="investment amount"
                      rules="required|integer"
                    >
                      <b-form-group
                        :label="$t('CompanyForm.investment_amount_seeking')"
                        label-for="InvestmentPrice"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="InvestmentPrice"
                          v-model="createCompanyProfile.Price"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="
                            $t('CompanyForm.investment_amount_seeking')
                          "
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                v-if="
                  createCompanyProfile.SelectedCategories.value ===
                  '62a1e61144fbaf36185a39de'
                "
                md="6"
              >
                <b-row>
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Currency"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('CompanyForm.Currency')"
                        label-for=""
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id=""
                          v-model="createCompanyProfile.SelectedCurrency"
                          :options="currencies"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="8">
                    <validation-provider
                      #default="{ errors }"
                      name="loan Amount"
                      rules="required|integer"
                    >
                      <b-form-group
                        :label="$t('CompanyForm.loan_amount_seeking')"
                        label-for="loanAmount"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="loanAmount"
                          v-model="createCompanyProfile.Price"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('CompanyForm.loan_amount_seeking')"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                v-if="
                  createCompanyProfile.SelectedCategories.value ===
                  '62a1e64944fbaf36185a39e2'
                "
                md="6"
              >
                <b-row>
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Currency"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('CompanyForm.Currency')"
                        label-for=""
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id=""
                          v-model="createCompanyProfile.SelectedCurrency"
                          :options="currencies"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="8">
                    <validation-provider
                      #default="{ errors }"
                      name="Selling or Leasing"
                      rules="required|integer"
                    >
                      <b-form-group
                        :label="$t('CompanyForm.selling_leasing')"
                        label-for="selling"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="selling"
                          v-model="createCompanyProfile.Price"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('CompanyForm.selling_leasing')"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                v-if="
                  createCompanyProfile.SelectedCategories.value ===
                  '62a1e5eb44fbaf36185a39d6'
                "
                md="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sale type"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('CompanyForm.sell_your_company')"
                    label-for="saleType"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="saleType"
                      v-model="createCompanyProfile.selectedSaleType"
                      :options="
                        $store.state.locale.locale == 'ar'
                          ? createCompanyProfile.SaleTypeOptionArabic
                          : createCompanyProfile.SaleTypeOption
                      "
                      :selectable="
                        (option) => !option.value.includes('select_value')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="createCompanyProfile.selectedSaleType.value === '1'"
                md="6"
              >
                <b-row>
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Currency"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('CompanyForm.Currency')"
                        label-for=""
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id=""
                          v-model="createCompanyProfile.SelectedCurrency"
                          :options="currencies"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="8">
                    <validation-provider
                      #default="{ errors }"
                      name="Price"
                      rules="required|integer"
                    >
                      <b-form-group
                        :label="$t('CompanyForm.Price')"
                        label-for="Price"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="Price"
                          v-model="createCompanyProfile.Price"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('CompanyForm.Price')"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.License_Number')"
                  label-for="License-Number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="License Number"
                    rules="required"
                  >
                    <b-form-input
                      id="License-Number"
                      v-model="createCompanyProfile.LicenseNumber"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.License_Number')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="company type"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('CompanyForm.legal_entity_type')"
                    label-for="Company-Type"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Company-Type"
                      v-model="createCompanyProfile.SelectedCompanyType"
                      :options="
                        $store.state.locale.locale === 'ar'
                          ? createCompanyProfile.CompanyTypeOptionArabic
                          : createCompanyProfile.CompanyTypeOption
                      "
                      :selectable="
                        (option) => !option.value.includes('select_value')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.overview_your_business')"
                  label-for="Highlights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Highlights"
                    :rules="`required|max:${$maxHighlightsUser}`"
                  >
                    <b-form-textarea
                      id="Highlights"
                      v-model="createCompanyProfile.Highlights"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.overview_your_business_p')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.description')"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="createCompanyProfile.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.description')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- address  tab-->
        <tab-content
          :title="$t('CompanyForm.Company_Address')"
          icon="feather icon-map-pin"
          :before-change="validationFormAddress"
        >
          <validation-observer ref="addressRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("CompanyForm.Company_Address") }}
                </h5>
                <small class="text-muted">
                  {{ $t("CompanyForm.Enter_Your_Address") }}</small
                >
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('CompanyForm.Company_country')"
                    label-for="Country"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Country"
                      v-model="createCompanyProfile.selectedCountry"
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                      @input="
                        retreiveStates(
                          createCompanyProfile.selectedCountry.value
                        )
                      "
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="State"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('CompanyForm.Company_state')"
                    label-for="State"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="State"
                      v-model="createCompanyProfile.selectedState"
                      :options="statesOptions"
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Company_city')"
                  label-for="City"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="City"
                    rules=""
                  >
                    <b-form-input
                      id="City"
                      v-model="createCompanyProfile.city"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.Company_city')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Company_street')"
                  label-for="otherInfo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="otherInfo"
                    rules=""
                  >
                    <b-form-input
                      id="otherInfo"
                      v-model="createCompanyProfile.companyStreet"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('CompanyForm.Company_street')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- social link -->
        <tab-content
          :title="$t('CompanyForm.Social_Links')"
          icon="feather icon-link"
          :before-change="validationFormSocial"
        >
          <validation-observer ref="socialRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("CompanyForm.Social_Links") }}
                </h5>
                <small class="text-muted">{{
                  $t("CompanyForm.Enter_Your_Social")
                }}</small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Facebook')"
                  label-for="facebook"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Facebook"
                    rules="url"
                  >
                    <b-form-input
                      id="facebook"
                      v-model="createCompanyProfile.facebookUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://facebook.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Twitter')"
                  label-for="twitter"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Twitter"
                    rules="url"
                  >
                    <b-form-input
                      id="twitter"
                      v-model="createCompanyProfile.twitterUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://twitter.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.LinkedIn')"
                  label-for="linked-in"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="LinkedIn"
                    rules="url"
                  >
                    <b-form-input
                      id="linked-in"
                      v-model="createCompanyProfile.linkedinUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://linkedin.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('CompanyForm.Website')"
                  label-for="Other"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Other"
                    rules="url"
                  >
                    <b-form-input
                      id="Other"
                      v-model="createCompanyProfile.OtherUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://other.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- files -->
        <tab-content
          :title="$t('CompanyForm.Attachment')"
          icon="feather icon-file-plus"
          :before-change="validationFormFiles"
        >
          <validation-observer ref="attachmentRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("CompanyForm.Attachment") }}
                </h5>
                <small class="text-muted"
                  >{{ $t("CompanyForm.Upload_your_files") }}
                </small>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company image"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label for="profile-picture"
                      >{{ $t("CompanyForm.main_company_image") }}

                      <!-- <i
                        id="CompanyImage-popover"
                        class="fa-solid fa-circle-info"
                      /> -->
                    </label>
                    <!-- <b-popover
                      target="CompanyImage-popover"
                      title=""
                      placement="top"
                      triggers="hover focus"
                      :content="$t('companyPopover.CompanyImage')"
                    /> -->
                    <b-form-file
                      v-model="createCompanyProfile.ProfilePicture"
                      required
                      accept="image/jpeg, image/png, image/gif"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :multiple="false"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Documents"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label for="Company-Documents"
                      >{{ $t("CompanyForm.company_documents") }}
                      <!-- <i
                        id="CompanyDocuments-popover"
                        class="fa-solid fa-circle-info"
                      /> -->
                    </label>
                    <!-- <b-popover
                      target="CompanyDocuments-popover"
                      title=""
                      placement="top"
                      triggers="hover focus"
                      :content="$t('companyPopover.CompanyDocuments')"
                    /> -->
                    <b-form-file
                      v-model="createCompanyProfile.CompanyDocuments"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      multiple
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Proof"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label for="Proof-Business"
                      >{{ $t("CompanyForm.proof_of_business") }}
                      <!-- <i
                        id="CompanyProof-popover"
                        class="fa-solid fa-circle-info"
                      /> -->
                    </label>
                    <!-- <b-popover
                      target="CompanyProof-popover"
                      title=""
                      placement="top"
                      triggers="hover focus"
                      :content="$t('companyPopover.CompanyProof')"
                    /> -->
                    <b-form-file
                      v-model="createCompanyProfile.ProofBusiness"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      multiple
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="company-photos"
                    >{{ $t("CompanyForm.company_images") }}
                    <!-- <i
                      id="companyPhotos-popover"
                      class="fa-solid fa-circle-info"
                    /> -->
                  </label>
                  <!-- <b-popover
                    target="companyPhotos-popover"
                    title=""
                    placement="top"
                    triggers="hover focus"
                    :content="$t('companyPopover.companyPhotos')"
                  /> -->
                  <validation-provider
                    #default="{ errors }"
                    name="Company images"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <b-form-file
                      v-model="createCompanyProfile.CompanyPhotos"
                      accept="image/jpeg, image/png, image/gif"
                      multiple
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="warning"
                  class="warning p-1"
                  style="color: red"
                  >{{ errorMessage }}</b-alert
                >
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <Footer class="company-form-footer" />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import Treeselect from "@riophae/vue-treeselect";
import store from "@/store";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VuePhoneNumberInput from "vue-phone-number-input";

import "../../../layouts/landpage/ValidationFrom.js";

import {
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
  BFormInput,
  BAlert,
  BFormDatepicker,
  BFormTextarea,
  BPopover,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import { required, email, max } from "@validations";

export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    VuePhoneNumberInput,
    BFormFile,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormDatepicker,
    BFormTextarea,
    BPopover,
    Treeselect,
    BAlert,
    BOverlay,
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    // 15th in two months
    const maxDate = new Date(today);
    // maxDate.setMonth(maxDate.getDay() - 1);
    // maxDate.setDate(15);
    return {
      translations: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Exemple :",
      },
      translationsArabic: {
        countrySelectorLabel: "رمز البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الهاتف",
        example: "مثال :",
      },
      myCountry: "",
      show: false,
      errorMessage: "",
      sendPhoneNumber: null,
      value: null,
      isValidNumber: false,
      url: "",
      between: "",
      integer: "",
      digits: "",
      alphaDash: "",
      length: "",
      phoneNumber: null,
      max: maxDate,
      createCompanyProfile: {
        selectedContry: "",
        name: "",
        emailValue: "",
        slugan: "",
        SelectedphoneCode: "",
        Phone: "",
        Establishment: "",
        EBITDA: "",
        MonthlySales: "",
        AnnualSales: "",
        PhysicalAssets: "",
        Assets: "",
        LicenseNumber: "",
        SelectedCompanyType: "",
        SelectedCategories: "",
        SelectedInvestmentCurrency: "",
        InvestmentPrice: "",
        SelectedLoanCurrency: "",
        loanAmount: "",
        SelectedSellingCurrency: "",
        selling: "",
        city: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        industries: [],
        Price: "",
        selectedIndustry: "",
        Highlights: "",
        description: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: "",
        otherInfo: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        companyPhoneCode: "",
        ProfilePicture: null,
        CompanyDocuments: [],
        ProofBusiness: [],
        CompanyPhotos: [],
        CompanyTypeOption: [
          { value: "1", text: "Sole Proprietorship/Sole Trader" },
          { value: "2", text: "General Partnership" },
          { value: "3", text: "Limited Liability Partnership (LLP)" },
          { value: "4", text: "Limited Liability Company (LLC)" },
          { value: "5", text: "Private Limited Company" },
          { value: "6", text: "Public Limited Company" },
          { value: "7", text: "S Corporation" },
          { value: "8", text: "C Corporation" },
          { value: "9", text: "Other" },
        ],
        CompanyTypeOptionArabic: [
          { value: "1", text: "مؤسسة فردية / تاجر وحيد" },
          { value: "2", text: "شراكة عامة" },
          { value: "3", text: "شراكة ذات مسؤولية محدودة(ش.ذ.م.م)" },
          { value: "4", text: "شركة ذات مسؤولية محدودة(ش.ذ.م.م)" },
          { value: "5", text: "شركة خاصة محدودة" },
          { value: "6", text: "شركه عالميه محدوده" },
          { value: "7", text: "S Corporation" },
          { value: "8", text: "C Corporation" },
          { value: "9", text: "غير ذلك" },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],
      },
      companyPopover: {
        EBITDA:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        MonthlySales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        AnnualSales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        CompanyImage:
          "Kindly upload your facility / office pictures as profiles with photos get higher rating and visibility. You can upload jpg, bmp, png, tiff, gif files which are less than 3MB in size.",
        CompanyDocuments:
          "Profiles with documents get higher rating and visibility. Documents uploaded are accessible only to members introduced to you. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif, mp4 files which are less than 3MB in size.",
        CompanyProof:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
        companyPhotos:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getCompanyCategories",
      industries: "profile/getAllIndustries",
      CountryCode: "profile/getCountryCode",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      user: "auth/user",
      statesOptions: "country/getAllStates",
    }),
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
    this.setCountryCode();
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      setCountryCode: "profile/setCountryCode",
      fetchCurrencies: "currency/retreiveCurrencies",
      createCompany: "profile/createCompany",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
    }),
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.createCompanyProfile.industries.length = 6;
      }
    },

    onUpdate(payload) {
      if (this.phoneNumber == null) {
        this.isValidNumber = true;
      } else {
        this.isValidNumber = payload.isValid;
      }
      this.companyPhoneCode = payload.countryCode;
      this.sendPhoneNumber = payload.phoneNumber;
    },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    formSubmitted() {
      this.show = true;
      const industries = [];
      const companyData = new FormData();
      companyData.append("userType", "company");
      // eslint-disable-next-line no-underscore-dangle
      companyData.append("userId", this.user._id);
      companyData.append("companyName", this.createCompanyProfile.name);
      companyData.append("companyEmail", this.createCompanyProfile.emailValue);
      companyData.append("companyLegalName", this.createCompanyProfile.slugan);
      // this.value.forEach((element) => {
      //   industries.push({
      //     id: element,
      //   });
      // });
      // if (this.value) {
      //   companyData.append("industries", JSON.stringify(industries));
      // }
      if (this.sendPhoneNumber) {
        companyData.append(
          "companyPhoneNumber",
          this.sendPhoneNumber.replace(/\s/g, "")
        );
        // companyData.append(
        //   "phoneNumber",
        //   this.sendPhoneNumber.replace(/\s/g, "")
        // );
      }
      // companyData.append("phoneCode", this.companyPhoneCode);
      companyData.append("companyPhoneCode", this.companyPhoneCode);
      companyData.append(
        "establishDate",
        this.createCompanyProfile.establishment
      );
      companyData.append("ebitda", this.createCompanyProfile.EBITDA);
      companyData.append(
        "avgMonthlySales",
        this.createCompanyProfile.MonthlySales
      );
      companyData.append("annualSales", this.createCompanyProfile.AnnualSales);
      companyData.append("assets", this.createCompanyProfile.Assets);
      companyData.append(
        "physicalAssets",
        this.createCompanyProfile.PhysicalAssets
      );
      companyData.append(
        "companyCategory",
        this.createCompanyProfile.SelectedCategories.value
      );
      companyData.append(
        "licenseNumber",
        this.createCompanyProfile.LicenseNumber
      );
      companyData.append(
        "companyType",
        this.createCompanyProfile.SelectedCompanyType.value
      );
      companyData.append(
        "saleType",
        this.createCompanyProfile.selectedSaleType.value
      );
      if (this.createCompanyProfile.Price) {
        companyData.append("price", this.createCompanyProfile.Price);
      }
      if (this.createCompanyProfile.SelectedCurrency) {
        companyData.append(
          "currency",
          this.createCompanyProfile.SelectedCurrency.value
        );
      }

      companyData.append("highlights", this.createCompanyProfile.Highlights);
      companyData.append("description", this.createCompanyProfile.description);
      companyData.append(
        "industries",
        JSON.stringify(this.createCompanyProfile.industries)
      );

      if (this.createCompanyProfile.city) {
        companyData.append("companyCity", this.createCompanyProfile.city);
      }

      if (this.createCompanyProfile.companyStreet) {
        companyData.append(
          "companyStreet",
          this.createCompanyProfile.companyStreet
        );
      }

      companyData.append(
        "companyState",
        this.createCompanyProfile.selectedState.value
      );
      companyData.append(
        "companyCountry",
        this.createCompanyProfile.selectedCountry.value
      );

      if (this.createCompanyProfile.facebookUrl) {
        companyData.append(
          "companyFacebookLink",
          this.createCompanyProfile.facebookUrl
        );
      }

      if (this.createCompanyProfile.linkedinUrl) {
        companyData.append(
          "companyLinkedinLink",
          this.createCompanyProfile.linkedinUrl
        );
      }
      if (this.createCompanyProfile.twitterUrl) {
        companyData.append(
          "companyTwitterLink",
          this.createCompanyProfile.twitterUrl
        );
      }
      if (this.createCompanyProfile.OtherUrl) {
        companyData.append(
          "companyOtherLinks",
          this.createCompanyProfile.OtherUrl
        );
      }

      companyData.append(
        "companyImage",
        this.createCompanyProfile.ProfilePicture
      );
      for (const i of Object.keys(this.createCompanyProfile.CompanyDocuments)) {
        companyData.append(
          "companyDocuments",
          this.createCompanyProfile.CompanyDocuments[i]
        );
      }
      if (this.createCompanyProfile.ProofBusiness) {
        for (const i of Object.keys(this.createCompanyProfile.ProofBusiness)) {
          companyData.append(
            "companyBusinessProof",
            this.createCompanyProfile.ProofBusiness[i]
          );
        }
      }

      if (this.createCompanyProfile.CompanyPhotos) {
        for (const i of Object.keys(this.createCompanyProfile.CompanyPhotos)) {
          companyData.append(
            "companyProductPhotos",
            this.createCompanyProfile.CompanyPhotos[i]
          );
        }
      }

      this.createCompany(companyData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.show = false;
          this.$router.replace("/pages/pricing", () => {
            this.$router.go(0);
          });
          // this.$router.push({ name: "pages-pricing" });
        })
        .catch((error) => {
          this.show = false;
          this.errorMessage = error.response.data.error.message;
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success && this.isValidNumber) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormFiles() {
      return new Promise((resolve, reject) => {
        this.$refs.attachmentRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormFiles() {
      return new Promise((resolve, reject) => {
        this.$refs.attachmentRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
  beforeCreate() {
    // $themeConfig.layout.menu.hidden = true;
    // console.log(store.state.appConfig.layout.menu.hidden);
    // console.log(store.state.appConfig.layout.footerType);
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
};
</script>

<style type="text/css">
[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.margin-top25 {
  margin-top: 25px;
}
</style>
<style>
@font-face {
  font-family: Tajawal;
  src: url(../../../../public/Tajawal-Regular.ttf);
}
body {
  font-family: Tajawal;
}
.company-form-footer {
  margin-top: 220px;
}
html[dir="rtl"]
  .vue-form-wizard
  .wizard-card-footer
  .wizard-footer-right
  .wizard-btn::after {
  content: "»";
}

html[dir="rtl"]
  .vue-form-wizard
  .wizard-card-footer
  .wizard-footer-left
  .wizard-btn::before {
  content: "«";
}

[dir="rtl"] .b-form-btn-label-control.form-control > .dropdown-menu {
  width: fit-content;
  z-index: 10001;
}
</style>
