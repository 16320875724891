import store from "../../store";
import adminMiddleware from "./middle-wares/dashboard/adminMiddleware";
import userMiddleware from "./middle-wares/dashboard/loginMiddleware";
import partnerMiddleware from "./middle-wares/dashboard/partnerMiddleware";
import guestMiddleware from "./middle-wares/dashboard/guestMiddleware";
import employeeMiddleware from "./middle-wares/dashboard/employeeMiddleware";
import completeRegistration from "./middle-wares/profiles/completeAccountRegistration";
import loginMiddleware from "./middle-wares/dashboard/loginMiddleware";
import partnerSuperEmployee from "./middle-wares/dashboard/partnerSuperEmployee";

export default [
  {
    path: "/profile/:username",
    name: "userprofile",
    component: () => import("@/views/nftdubai/profile/Profile.vue"),
  },
  {
    path: "/live/private/:username",
    name: "userLive",
    meta: {
      middleware: [userMiddleware],
    },
    component: () => import("@/views/nftdubai/profile/Live.vue"),
  },
  {
    path: "/live/public/:username",
    name: "userLive",
    component: () => import("@/views/nftdubai/profile/Live.vue"),
  },
  {
    path: "/meeting",
    name: "meeting",
    meta: {
      middleware: [userMiddleware],
    },
    component: () => import("@/views/nftdubai/meeting/StartMeeting.vue"),
  },
  {
    path: "/timeline",
    name: "timeline",
    component: () => import("@/views/nftdubai/timeline/Timeline.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      middleware: [adminMiddleware, employeeMiddleware],
    },
    component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
  },
  {
    path: "/partner-dashboard",
    name: "partner-dashboard",
    meta: {
      middleware: [partnerMiddleware],
    },
    component: () => import("@/views/nftdubai/partner-dashboard/PartnerDashboard.vue"),
  },
  {
    path: "/verifications",
    name: "verifications",
    meta: {
      middleware: [partnerSuperEmployee],
    },
    component: () => import("@/views/nftdubai/verifications/Verifications.vue"),
  },
  {
    path: "/complaints",
    name: "complaints",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/complaints/Complaints.vue"),
  },
  {
    path: "/complaints/reasons",
    name: "complainsReasons",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/complaints/Reasons.vue"),
  },
  {
    path: "/complaints/details/:id",
    name: "complainDetails",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/complaints/Details.vue"),
  },
  {
    path: "/verifications/details/:id",
    name: "verificationsDetails",
    meta: {
      middleware: [partnerSuperEmployee],
    },
    component: () => import("@/views/nftdubai/verifications/Details/VerificationDetails.vue"),
  },
  {
    path: "/ideas",
    name: "ideas",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/ideas/IdeasList.vue"),
  },
  {
    path: "/projects",
    name: "projects",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/projects/ProjectsList.vue"),
  },
  {
    path: "/products",
    name: "products",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/products/ProductsList.vue"),
  },
  {
    path: "/services",
    name: "services",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/services/ServicesList.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/users/UsersList"),
  },
  {
    path: "/users/activate-reward/:id",
    name: "activeUserReward",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/users/ActivateReward"),
  },
  {
    path: "/users-activity",
    name: "users-activity",
    component: () => import("@/views/nftdubai/users-activity/UsersActivity"),
    meta: {
      middleware: [employeeMiddleware],
    },
  },
  {
    path: "/categories",
    name: "categories",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/categories/CategoriesList"),
  },
  {
    path: "/industries",
    name: "industries",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/industries/IndustriesList"),
  },
  {
    path: "/skills",
    name: "skills",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/skills/SkillsList"),
  },
  {
    path: "/permissions",
    name: "permissions",
    meta: {
      middleware: [adminMiddleware],
    },
    component: () => import("@/views/nftdubai/permission/PermissionsList"),
  },
  {
    path: "/mailNotifications",
    name: "mailNotifications",
    middleware: [employeeMiddleware],
    component: () => import("@/views/nftdubai/mail-notifications/NotificationsList.vue"),
  },
  {
    path: "/roles",
    name: "roles",
    middleware: [adminMiddleware],
    component: () => import("@/views/nftdubai/roles/RolesList"),
  },
  {
    path: "/plans",
    name: "plans",
    middleware: [employeeMiddleware],
    component: () => import("@/views/nftdubai/plans/PlansList"),
  },
  {
    path: "/subscription",
    name: "subscription",
    middleware: [employeeMiddleware],
    component: () => import("@/views/nftdubai/subscriptions/SubScriptionList"),
  },
  {
    path: "/contact/messages",
    name: "contact-messages",
    middleware: [employeeMiddleware],
    component: () => import("@/views/nftdubai/contacts/ContactList"),
  },
  {
    path: "/partners",
    name: "partners",
    middleware: [employeeMiddleware],
    component: () => import("@/views/nftdubai/partners/PartnersList.vue"),
  },
  /*
    Currencies
   */
  {
    path: "/currencies",
    name: "currencies",
    middleware: [employeeMiddleware],
    component: () => import("@/views/nftdubai/currencies/CurrenciesList"),
  },

  {
    path: "/blogs",
    name: "blogs",
    component: () => import("@/views/nftdubai/blogs/ListAll.vue"),
    meta: {
      middleware: [employeeMiddleware],
      // pageTitle: 'Blogs list',
      // breadcrumb: [
      //   {
      //     text: 'Home',
      //     to: '/index',
      //   },
      //   {
      //     text: 'Blogs ist',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/blogs/create",
    name: "blogs-addNew",
    component: () => import("@/views/nftdubai/blogs/Add.vue"),
    meta: {
      middleware: [employeeMiddleware],
      pageTitle: "Blogs list",
      breadcrumb: [
        {
          text: "Home",
          to: "/index",
        },
        {
          text: "Blogs ist",
          to: "/blogs",
        },
        {
          text: "Create blog",
          active: true,
        },
      ],
    },
  },
  {
    path: "/blogs/edit/:id",
    name: "blog-edit",
    component: () => import("@/views/nftdubai/blogs/Edit.vue"),
    meta: {
      middleware: [employeeMiddleware],
      pageTitle: "Blogs list",
      breadcrumb: [
        {
          text: "Home",
          to: "/index",
        },
        {
          text: "Blogs ist",
          to: "/blogs",
        },
        {
          text: "Edit blog",
          active: true,
        },
      ],
    },
  },

  {
    path: "/faq/list",
    name: "faq-list",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/faq/FaqsList.vue"),
    // meta: {
    //   pageTitle: "Blogs list",
    //   breadcrumb: [
    //     {
    //       text: "Home",
    //       to: "/index",
    //     },
    //     {
    //       text: "Blogs ist",
    //       active: true,
    //     },
    //   ],
    // },
  },

  /* Settings */
  {
    path: "/settings/countries",
    name: "countries",
    meta: {
      middleware: [employeeMiddleware],
    },
    component: () => import("@/views/nftdubai/settings/countries/CountriesList"),
  },
  /* Chat */
  {
    path: "/inbox/:sessionId",
    name: "inboxSession",
    component: () => import("@/views/nftdubai/chat/Chat.vue"),
    meta: {
      middleware: [loginMiddleware],
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },
  {
    path: "/bookmarks",
    name: "bookmarks",
    meta: {
      middleware: [loginMiddleware],
    },
    component: () => import("@/views/nftdubai/bookmarks/BookmarksList.vue"),
  },
  {
    path: "/inbox",
    name: "inbox",
    component: () => import("@/views/nftdubai/chat/Chat.vue"),
    meta: {
      middleware: [loginMiddleware],
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },

  {
    path: "/notifications",
    name: "notifications",
    meta: {
      middleware: [loginMiddleware],
    },
    component: () => import("@/views/nftdubai/notifications/Notifications.vue"),
  },
  {
    path: "/tickets/departments",
    name: "SupportDepartments",
    meta: {
      middleware: [adminMiddleware],
    },
    component: () => import("@/views/nftdubai/support-tickets/Departments/Departments.vue"),
  },
  {
    path: "/tickets/departments/add",
    name: "SupportDepartmentsAdd",
    meta: {
      middleware: [loginMiddleware],
    },
    component: () => import("@/views/nftdubai/support-tickets/Departments/Add.vue"),
  },
  {
    path: "/tickets/departments/edit/:id",
    name: "SupportDepartmentsEdit",
    meta: {
      middleware: [adminMiddleware],
    },
    component: () => import("@/views/nftdubai/support-tickets/Departments/Edit.vue"),
  },
  {
    path: "/tickets",
    name: "SupportTickets",
    meta: {
      middleware: [loginMiddleware],
    },
    component: () => import("@/views/nftdubai/support-tickets/Tickets/TicketsList.vue"),
  },
  {
    path: "/tickets/view/:id",
    name: "SupportTicketsView",
    meta: {
      middleware: [loginMiddleware],
    },
    component: () => import("@/views/nftdubai/support-tickets/Tickets/View.vue"),
  },
  {
    path: "/tickets/create",
    name: "CreateSupportTicket",
    meta: {
      middleware: [loginMiddleware],
    },
    component: () => import("@/views/nftdubai/support-tickets/Tickets/Create.vue"),
  },
  {
    path: "/tickets/settings",
    name: "SupportSettings",
    meta: {
      middleware: [adminMiddleware],
    },
    component: () => import("@/views/nftdubai/support-tickets/Settings/Settings.vue"),
  },
  {
    name: "Rewards",
    path: "/rewards",
    meta: {
      middleware: [loginMiddleware],
    },
    component: () => import("@/views/nftdubai/rewards/Rewards.vue"),
  },
  {
    name: "RewardsTransfer",
    path: "/rewards/transfer",
    meta: {
      middleware: [loginMiddleware],
    },
    component: () => import("@/views/nftdubai/rewards/Transfer.vue"),
  },
  {
    path: "/testing",
    name: "testing",
    component: () => import("@/views/nftdubai/Testing.vue"),
  },
];
