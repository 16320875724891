import mock from "@/@fake-db/mock";
/* eslint-disable global-require */
const data = {
  // blog list
  blogList: [
    {
      id: 1,
      img: require("@/assets/images/slider/02.jpg"),
      title: "Features of NFT marketplace",
      avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
      userFullName: "Ghani Pradita",
      blogPosted: "june 29, 2022",
      tags: ["NFT", "BlockChain"],
      excerpt:
        "Donut fruitcake soufflé apple pie candy canes jujubes croissant chocolate bar ice cream.",
      comment: 3,
    },
    {
      id: 2,
      img: require("@/assets/images/slider/06.jpg"),
      title:
        "NFT Dubai Holdings Recruitment:( One of the main sections of the group (CV Shots",
      avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
      userFullName: "Jorge Griffin",
      blogPosted: "june 29, 2022",
      tags: ["Gaming", "Video"],
      excerpt:
        "Apple pie caramels lemon drops halvah liquorice carrot cake. Tiramisu brownie lemon drops.",
      comment: 2100,
    },
    {
      id: 3,
      img: require("@/assets/images/slider/04.jpg"),
      title: "How to Find an NFT You Love",
      avatar: require("@/assets/images/portrait/small/avatar-s-3.jpg"),
      userFullName: "Claudia Neal",
      blogPosted: "Jan 10, 2020",
      tags: ["Gaming", "Food"],
      excerpt:
        "Tiramisu jelly-o chupa chups tootsie roll donut wafer marshmallow cheesecake topping.",
      comment: 243,
    },
    {
      id: 4,
      img: require("@/assets/images/slider/03.jpg"),
      title: "Withhold a Compelling Piece of Information",
      avatar: require("@/assets/images/portrait/small/avatar-s-14.jpg"),
      userFullName: "Fred Boone",
      blogPosted: "Jan 10, 2020",
      tags: ["Video"],
      excerpt:
        "Croissant apple pie lollipop gingerbread. Cookie jujubes chocolate cake icing cheesecake.",
      comment: 10,
    },
    {
      id: 5,
      img: require("@/assets/images/slider/09.jpg"),
      title: "Unadvertised Bonus Opening: Share a Quote",
      avatar: require("@/assets/images/portrait/small/avatar-s-13.jpg"),
      userFullName: "Billy French",
      blogPosted: "Jan 10, 2020",
      tags: ["Quote", "Fashion"],
      excerpt:
        "Muffin liquorice candy soufflé bear claw apple pie icing halvah. Pie marshmallow jelly.",
      comment: 319,
    },
    {
      id: 6,
      img: require("@/assets/images/slider/10.jpg"),
      title: "Ships at a distance have Every Man’s Wish on Board",
      avatar: require("@/assets/images/portrait/small/avatar-s-13.jpg"),
      userFullName: "Helena Hunt",
      blogPosted: "Jan 10, 2020",
      tags: ["Fashion", "Video"],
      excerpt:
        "A little personality goes a long way, especially on a business blog. So don’t be afraid to let loose.",
      comment: 1500,
    },
  ],

  // sidebar
  blogSidebar: {
    recentPosts: [
      {
        id: 7,
        img: require("@/assets/images/blogs/blog-1.jpg"),
        title: "Features of NFT marketplace",
        createdTime: "june 29, 2022",
      },
      {
        id: 8,
        img: require("@/assets/images/blogs/blog-2.png"),
        title: "NFT Dubai Holding Recruitment",
        createdTime: "june 29, 2022",
      },
      {
        id: 9,
        img: require("@/assets/images/blogs/blog-3.jpg"),
        title: "How to Find an NFT You Love",
        createdTime: "june 29, 2022",
      },
      {
        id: 10,
        img: require("@/assets/images/blogs/blog-3.jpg"),
        title: "How to Find an NFT You Love",
        createdTime: "june 29, 2022",
      },
    ],
    categories: [
      { category: "NFT", icon: "WatchIcon" },
      { category: "Blockchain", icon: "ShoppingCartIcon" },
      { category: "Recruitment", icon: "CommandIcon" },
      { category: "Project", icon: "HashIcon" },
      // { category: "Video", icon: "VideoIcon" },
    ],
  },

  // detail
  blogDetail: {
    blog: {
      img: require("@/assets/images/blogs/cover.jpg"),
      title: "Features of NFT marketplace",
      avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
      userFullName: "Ghani Pradita",
      createdTime: "Jan 10, 2020",
      tags: ["NFT", "BlockChain"],
      content:
        "<h2>With so many projects out there, we know it can seem hard to find an NFT you love. Here are a few things you may want to keep in mind to simplify the process.</h2><ul><li>Consider the purpose of purchasing. Are you buying an NFT you’ll use for a profile photo (PFP) How long do you plan to own the NFT? Asking these questions will help you narrow down the search as you browse.</li><li>Research the project. Start with the official project website. This is a great starting point to understand the motivations and goals of the founder(s). Many projects these days have a roadmap with lots of milestones. Does it seem realistic, or is it overly ambitious? Make sure to also check the project’s social media.</li><li>The founders. Most times, founders drive the vision, future, and success of a project. identity is publicly known so you are able to research their background/history of success. Unboxed founders have not revealed their identity and often go by an alias.</li><li>Rarity features. NFTs are unique and each one has a unique rarity. Usually, NFTs that are rare are more sought after. You can see the traits, which drive rarity, in the left-hand bar when on a project page.</li><li>Project stats (e.g., floor price, volume, owners). NFT users often focus on floor price. Yes – some projects might “moon” overnight, but you shouldn’t overlook other stats like volume and the number of owners. A collection with a high floor price and no volume is meaningless.</li><li>If this is a short-term purchase, factoring in the volume of how many users are buying and selling at any given time will be important if you ever decide to sell. Likewise, for profile picture projects (PFPs), projects having fewer owners may have difficulty gaining long-term traction and building community.</li><li>Ultimately, there are many points to think about when purchasing an NFT. Once you have your NFT, make sure to share it with your fellow collectors!</li></ul>",
      comments: 22,
      bookmarked: 30,
      UserComment: [
        {
          avatar: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
          fullName: "Willie Clark",
          comment: "very nice",
        },
      ],
    },
    comments: [
      {
        avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2020",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },

  // edit
  blogEdit: {
    avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
    userFullName: "Chad Alexander",
    createdTime: "May 24, 2020",
    blogTitle: "The Best Features Coming to iOS and Web design",
    blogCategories: ["Fashion", "Gaming"],
    slug: "the-best-features-coming-to-ios-and-web-design",
    status: "Published",
    excerpt:
      "<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>",
    featuredImage: require("@/assets/images/slider/03.jpg"),
  },
};
/* eslint-disable global-require */
mock.onGet("/blog/list/data").reply(() => [200, data.blogList]);
mock.onGet("/blog/list/data/sidebar").reply(() => [200, data.blogSidebar]);
mock.onGet("/blog/list/data/detail").reply(() => [200, data.blogDetail]);
mock.onGet("/blog/list/data/edit").reply(() => [200, data.blogEdit]);
