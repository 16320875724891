<template>
  <div id="dropdown-grouped" variant="link" class="dropdown-language locale-custom" right>
    <div @click="changeLanguage()">
      <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
      <!-- <span class="ml-50 text-body">{{ currentLocale.name }}</span> -->
    </div>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import store from "@/store";
import { setLogLevel } from "@firebase/app";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      colorLang: "",
      windowWidth: window.innerWidth,
    };
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale !== this.$i18n.locale);
    },
  },
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);

  //   if (this.windowWidth < 700) {
  //     this.colorLang = "color-b";
  //   }
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  methods: {
    ...mapActions({
      changeLang: "locale/changeLang",
      checkRTL: "app-config/changeLang",
    }),

    // handleScroll(event) {
    //   // Any code to be executed when the window is scrolled
    //   // console.log(this.windowWidth);
    //   if (this.windowWidth > 700) {
    //     if (window.scrollY > 50) {
    //       this.colorLang = "color-b";
    //     }
    //     if (window.scrollY <= 50) {
    //       this.colorLang = "color-w";
    //     }
    //   } else {
    //     this.colorLang = "color-b";
    //   }
    // },

    changeLanguage() {
      if (localStorage.getItem("language") == "ar") {
        this.setLocale("en");
      } else {
        this.setLocale("ar");
      }
    },

    setLocale(value) {
      // alert(this.$i18n.locale);
      this.$i18n.locale = value;
      // store.commit("appConfig/TOGGLE_RTL", true);
      if (value === "ar") {
        store.commit("appConfig/TOGGLE_RTL", true);
        // store.dispatch("local/setLanguage", "ar");
        // store.commit("locale/changeLang");
        this.changeLang();
      }
      if (value === "en") {
        store.commit("appConfig/TOGGLE_RTL", false);
        this.changeLang();
      }
      window.location.reload();
    },
    checkDir() {
      // console.log(localStorage.getItem("language"));

      if (localStorage.getItem("language") == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
        document.body.style.fontFamily = "Tajawal";
      } else {
        document.documentElement.setAttribute("dir", "ltr");
        // document.body.style.fontFamily = "Montserrat,Helvetica,Arial, serif";
        document.body.style.fontFamily = "nftbplus,sans-serif";
      }
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "ar",
        img: require("@/assets/images/flags/ar.png"),
        name: "عربي",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
  mounted() {
    this.checkDir();
  },
};
</script>

<style scoped>
.text-body {
  color: #366ce3 !important;
}
.color-w {
  color: #fff;
}
.color-b {
  color: #366ce3;
}
.locale-custom {
  margin-top: 3px;
}
</style>
