import { initializeApp, getApps } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  connectAuthEmulator,
  FacebookAuthProvider,
} from 'firebase/auth'

// const firebaseConfig = {
//   apiKey: 'AIzaSyDBBOqy81M2GlZkxkFQ8g-iYmDvmvftNps',
//   authDomain: 'nftdubai-1f0c9.firebaseapp.com',
//   projectId: 'nftdubai-1f0c9',
//   storageBucket: 'nftdubai-1f0c9.appspot.com',
//   messagingSenderId: '974570664091',
//   appId: '1:974570664091:web:995bede437c74153e1963e',
// }
const firebaseConfig = {
  apiKey: 'AIzaSyBZ6pPVPCp2Z99QKkAajXOgtxBpz_PCa6M',
  authDomain: 'nftdubaiae.firebaseapp.com',
  projectId: 'nftdubaiae',
  storageBucket: 'nftdubaiae.appspot.com',
  messagingSenderId: '142702963047',
  appId: '1:142702963047:web:726eea09ba7e8dcf6aa7c2',
  measurementId: 'G-0PHHS79XPP',
}

const apps = getApps()
const firebaseApp = apps.length ? apps[0] : initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)
const auth = getAuth(firebaseApp)
// connectAuthEmulator(auth, 'http://localhost:9099/')
export {
  firebaseApp, messaging, auth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider,
}
