<template>
  <div class="freelancer-form">
    <Header class="" />
    <h2 class="margin-top25" />
    <b-overlay :show="show" rounded="sm">
      <form-wizard
        color="#366ce3"
        :title="$t('FreelancerForm.Register_freelancer')"
        :subtitle="$t('FreelancerForm.Complete_your_account')"
        shape="square"
        :finish-button-text="$t('common.Submit')"
        :next-button-text="$t('common.Next')"
        :back-button-text="$t('common.Previous')"
        class="mb-3 pt-1"
        @on-complete="formSubmitted"
      >
        <!-- freelancer details tab -->
        <tab-content
          :title="$t('FreelancerForm.Freelancer_Details')"
          icon="feather icon-file-text"
          :before-change="validationForm"
        >
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("FreelancerForm.Freelancer_Details") }}
                </h5>
                <small class="text-muted">
                  {{ $t("FreelancerForm.Enter_Freelancer_Details") }}
                </small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.Full_Name')"
                  label-for="Fomraml_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    rules="required"
                  >
                    <b-form-input
                      id="Fomraml_name"
                      v-model="createFreelancerProfile.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('FreelancerForm.Full_Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.Email')"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="createFreelancerProfile.emailValue"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="john.doe@email.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.Short_Name')"
                  label-for="slugan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="slugan"
                    rules="required"
                  >
                    <b-form-input
                      id="slugan"
                      v-model="createFreelancerProfile.slugan"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('FreelancerForm.Short_Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.Phone_Number')"
                  label-for="Phone"
                >
                  <validation-provider name="phone" rules="required">
                    <VuePhoneNumberInput
                      v-model="phoneNumber"
                      color="#ea5455"
                      error-color="#ea5455"
                      @update="onUpdate"
                      :translations="
                        $store.state.locale.locale == 'ar'
                          ? translationsArabic
                          : translations
                      "
                    />

                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small v-if="!isValidNumber" class="text-danger">{{
                      $t("common.phone_Invalid")
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.PassportID')"
                  label-for="Passport-ID"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Passport ID"
                    rules="required"
                  >
                    <b-form-input
                      id="Passport-ID"
                      v-model="createFreelancerProfile.PassportID"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('FreelancerForm.PassportID')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Gender"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('FreelancerForm.Gender')"
                    label-for="Gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Gender"
                      v-model="createFreelancerProfile.selectedGender"
                      :options="
                        $store.state.locale.locale == 'ar'
                          ? createFreelancerProfile.GenderOptionArabic
                          : createFreelancerProfile.GenderOption
                      "
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Freelancer Information tab -->
        <tab-content
          :title="$t('FreelancerForm.Freelancer_Info')"
          icon="feather icon-user"
          :before-change="validationFormInfo"
        >
          <validation-observer ref="infoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("FreelancerForm.Freelancer_Info") }}</h5>
                <small class="text-muted">{{
                  $t("FreelancerForm.Enter_Freelancer_Info")
                }}</small>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Nationality"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('FreelancerForm.Your_nationality')"
                    label-for="Nationality"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Nationality"
                      v-model="createFreelancerProfile.selectedNationality"
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('FreelancerForm.industries_belong_to')"
                    label-for="Industry"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div id="app">
                      <treeselect
                        v-model="createFreelancerProfile.selectedIndustry"
                        :multiple="true"
                        :placeholder="$t('common.Select')"
                        :options="industries"
                        @input="handeTreeSelect"
                        :normalizer="
                          $store.state.locale.locale == 'ar'
                            ? normalizerArabic
                            : normalizer
                        "
                      />
                    </div>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('FreelancerForm.Your_country')"
                    label-for="Country"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Country"
                      v-model="createFreelancerProfile.selectedCountry"
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                      @input="
                        retreiveStates(
                          createFreelancerProfile.selectedCountry.value
                        )
                      "
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="State"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('FreelancerForm.Your_state')"
                    label-for="State"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="State"
                      v-model="createFreelancerProfile.selectedState"
                      :options="statesOptions"
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="SkiSkillsIDlls"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('FreelancerForm.Your_skills')"
                    label-for="SkillsID"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="SkillsID"
                      v-model="createFreelancerProfile.selectedSkills"
                      :options="skills"
                      :selectable="
                        () =>
                          createFreelancerProfile.selectedSkills.length <
                          `${$maxSkillsUser}`
                      "
                      multiple
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.Brief_overview_yourself')"
                  label-for="Highlights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Highlights"
                    :rules="`required|max:${$maxHighlightsUser}`"
                  >
                    <b-form-textarea
                      id="Highlights"
                      v-model="createFreelancerProfile.Highlights"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="
                        $t('FreelancerForm.Brief_overview_yourself')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.description_yourself')"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="createFreelancerProfile.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('FreelancerForm.description_yourself')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Experience  tab-->
        <tab-content
          :title="$t('FreelancerForm.Freelancer_Experience')"
          icon="feather icon-tag"
          :before-change="validationFormExperience"
        >
          <validation-observer ref="ExperienceRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("FreelancerForm.About_you") }}</h5>
                <small class="text-muted">{{
                  $t("FreelancerForm.Enter_Your_Experience")
                }}</small>
                <b-col md="12">
                  <b-form-group label="" label-for="language">
                    <validation-provider
                      #default="{ errors }"
                      name="language"
                      rules=""
                    >
                      <form-repeater-basic :items="items" />
                      <!-- <b-form-textarea
                    id="description"
                    v-model="createCompanyProfile.description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Descripe your company"
                  /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="" label-for="certification">
                    <validation-provider
                      #default="{ errors }"
                      name="certification"
                      rules="required"
                    >
                      <certificate :certificates="certificates" />
                      <!-- <b-form-textarea
                    id="description"
                    v-model="createCompanyProfile.description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Descripe your company"
                  /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="" label-for="certification">
                    <validation-provider
                      #default="{ errors }"
                      name="certification"
                      rules=""
                    >
                      <Institute :institute="institute" />
                      <!-- <b-form-textarea
                    id="description"
                    v-model="createCompanyProfile.description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Descripe your company"
                  /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="" label-for="Experience">
                    <validation-provider
                      #default="{ errors }"
                      name="Experience"
                      rules=""
                    >
                      <Experience :experiences="experiences" />
                      <!-- <b-form-textarea
                    id="description"
                    v-model="createCompanyProfile.description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Descripe your company"
                  /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- social link -->
        <tab-content
          :title="$t('FreelancerForm.Social_Links')"
          icon="feather icon-link"
          :before-change="validationFormSocial"
        >
          <validation-observer ref="socialRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("FreelancerForm.Social_Links") }}</h5>
                <small class="text-muted">{{
                  $t("FreelancerForm.Enter_Social_Links")
                }}</small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.Facebook')"
                  label-for="facebook"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Facebook"
                    rules="url"
                  >
                    <b-form-input
                      id="facebook"
                      v-model="createFreelancerProfile.facebookUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://facebook.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.Twitter')"
                  label-for="twitter"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Twitter"
                    rules="url"
                  >
                    <b-form-input
                      id="twitter"
                      v-model="createFreelancerProfile.twitterUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://twitter.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.LinkedIn')"
                  label-for="linked-in"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="LinkedIn"
                    rules="url"
                  >
                    <b-form-input
                      id="linked-in"
                      v-model="createFreelancerProfile.linkedinUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://linkedin.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('FreelancerForm.Website')"
                  label-for="Other"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Other"
                    rules="url"
                  >
                    <b-form-input
                      id="Other"
                      v-model="createFreelancerProfile.OtherUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://other.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- files -->
        <tab-content
          :title="$t('FreelancerForm.Attachment')"
          icon="feather icon-file-plus"
          :before-change="validationFormFiles"
        >
          <validation-observer ref="attachmentRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("FreelancerForm.Attachment") }}</h5>
                <small class="text-muted"
                  >{{ $t("FreelancerForm.Upload_your_files") }}
                </small>
              </b-col>
              <!-- <b-col md="8">
              <b-form-group
                label="Add Personal Photo"
                label-for="PersonalPhoto"
              >
                <vue-dropzone
                  id="PersonalPhoto"
                  ref="myVueDropzone"
                  v-model="createFreelancerProfile.PersonalPhoto"
                  :options="dropzoneOptionsPersonalImage"
                  @click="removeThisFile(UploadFile)"
                />
              </b-form-group>
            </b-col> -->
              <!-- <b-col md="8">
              <b-form-group
                label="Freelancer Prove (Passport or national ID)"
                label-for="Freelancer-Prove"
              >
                <vue-dropzone
                  id="Freelancer-Prove"
                  ref="myVueDropzone"
                  v-model="createFreelancerProfile.FreelancerProve"
                  :options="dropzoneOptionsProof"
                  @click="removeThisFile(UploadFile)"
                />
              </b-form-group>
            </b-col> -->
              <!-- <b-col md="8">
              <b-form-group
                label="Add your certificates"
                label-for="Freelancer-certificates"
              >
                <vue-dropzone
                  id="Freelancer-certificates"
                  ref="myVueDropzone"
                  v-model="createFreelancerProfile.FreelancerCertificates"
                  :options="dropzoneOptionsCertificates"
                  @click="removeThisFile(UploadFile)"
                />
              </b-form-group>
            </b-col> -->
              <!-- <b-col md="8">
              <b-form-group
                label="Attach experenice, docs and relevant Docouments"
                label-for="relevant-Docouments"
              >
                <vue-dropzone
                  id="relevant-Docouments"
                  ref="myVueDropzone"
                  v-model="createFreelancerProfile.FreelancerRelevant"
                  :options="dropzoneOptionRelevant"
                  @click="removeThisFile(UploadFile)"
                />
              </b-form-group>
            </b-col> -->

              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Personal Photo"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label for="PersonalPhoto"
                      >{{ $t("FreelancerForm.Personal_Photo") }}
                    </label>
                    <b-form-file
                      id="PersonalPhoto"
                      v-model="createFreelancerProfile.PersonalPhoto"
                      required
                      accept="image/jpeg, image/png, image/gif"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :multiple="false"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Freelancer Prove"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label for="Freelancer-Prove"
                      >{{ $t("FreelancerForm.Freelancer_Prove") }}
                    </label>
                    <b-form-file
                      id="Freelancer-Prove"
                      v-model="createFreelancerProfile.FreelancerProve"
                      required
                      accept="image/jpeg, image/png, image/gif"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :multiple="false"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="certificates"
                    :rules="`size:${$sizeFilesUser}`"
                  >
                    <label for="Freelancer-certificates"
                      >{{ $t("FreelancerForm.your_certificates") }}
                    </label>
                    <b-form-file
                      id="Freelancer-certificates"
                      v-model="createFreelancerProfile.FreelancerCertificates"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      multiple
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="relevant docouments"
                    :rules="`size:${$sizeFilesUser}`"
                  >
                    <label for="relevant-Docouments"
                      >{{ $t("FreelancerForm.relevant_Docouments") }}
                    </label>
                    <b-form-file
                      id="relevant-Docouments"
                      v-model="createFreelancerProfile.FreelancerRelevant"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      multiple
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="warning"
                  class="warning p-1"
                  style="color: red"
                  >{{ errorMessage }}</b-alert
                >
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <Footer class="company-form-footer" />
  </div>
</template>

<script>
import store from "@/store";

import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAlert,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import { codeIcon } from "./code";
import Experience from "../form-repeater/ExperiencRepeater.vue";
import Institute from "../form-repeater/CertificationRepeater.vue";
import Certificate from "../form-repeater/EducationRepeater.vue";
import FormRepeaterBasic from "../form-repeater/LanguageRepeater.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
// eslint-disable-next-line import/order
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import "../../../layouts/landpage/ValidationFrom.js";

export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BAlert,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    FormRepeaterBasic,
    Certificate,
    Experience,
    Institute,
    VuePhoneNumberInput,
    Treeselect,
    BFormFile,
    BOverlay,
  },
  data() {
    return {
      translations: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Exemple :",
      },
      translationsArabic: {
        countrySelectorLabel: "رمز البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الهاتف",
        example: "مثال :",
      },
      show: false,
      errorMessage: "",
      isValidNumber: null,
      phoneNumber: null,
      phoneCode: "",
      sendPhoneNumber: null,
      experiences: [],
      items: [],
      certificates: [],
      institute: [],
      createFreelancerProfile: {
        selectedContry: "",
        name: "",
        emailValue: "",
        slugan: "",
        SelectedphoneCode: "",
        Phone: "",
        PassportID: "",
        selectedGender: "",
        selectedNationality: "",
        selectedIndustry: [],
        selectedSkills: [],
        Highlights: "",
        description: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: "",
        otherInfo: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",

        PersonalPhoto: null,
        FreelancerProve: null,
        FreelancerCertificates: [],
        FreelancerRelevant: [],
        GenderOption: [
          { value: "male", text: "Male" },
          { value: "female", text: "Female" },
        ],
        GenderOptionArabic: [
          { value: "male", text: "ذكر" },
          { value: "female", text: "أنثى" },
        ],
      },
      dropzoneOptionsPersonalImage: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsProof: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsCertificates: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionRelevant: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getInvestorCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      user: "auth/user",
      skills: "profile/getAllSkills",
      statesOptions: "country/getAllStates",
    }),
  },

  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      createFreelancer: "profile/createFreelancer",
      fetchCountries: "country/retreiveCountries",
      fetchSkills: "profile/retreiveSkills",
      fetchStates: "country/retreiveStates",
    }),
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.createFreelancerProfile.selectedIndustry.length = 6;
      }
    },
    onUpdate(payload) {
      if (this.phoneNumber == null) {
        this.isValidNumber = true;
      } else {
        this.isValidNumber = payload.isValid;
      }
      this.sendPhoneNumber = payload.phoneNumber;

      this.phoneCode = payload.countryCode;
    },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    formSubmitted() {
      this.show = true;
      const formatIndustries = [];
      const formatSkills = [];
      const formatLanguage = [];
      const formatDegree = [];
      const formatExperince = [];
      const formatInstitute = [];
      const formatLocation = [];
      if (this.createFreelancerProfile.selectedIndustry) {
        this.createFreelancerProfile.selectedIndustry.forEach((element) => {
          formatIndustries.push({
            _id: element,
          });
        });
      }
      this.createFreelancerProfile.selectedSkills.forEach((element) => {
        formatSkills.push({
          _id: element.value,
        });
      });
      this.items.forEach((element) => {
        formatLanguage.push({
          name: element.name.text,
          level: element.level,
        });
      });
      this.certificates.forEach((element) => {
        formatDegree.push({
          degree: element.Degree,
          date: element.DegreeDate,
          institute: element.university,
          location: element.country.value,
        });
      });
      this.experiences.forEach((element) => {
        formatExperince.push({
          name: element.name,
          years: element.years,
          company: element.company,
          location: element.country.value,
        });
      });
      this.institute.forEach((element) => {
        formatInstitute.push({
          date: element.certDate,
          name: element.certificate,
        });
      });
      formatLocation.push({
        stateId: this.createFreelancerProfile.selectedState.value,
        countryId: this.createFreelancerProfile.selectedCountry.value,
      });
      const freelancerData = new FormData();
      // eslint-disable-next-line no-underscore-dangle

      /* ----------- Tab1  ----------*/
      freelancerData.append("userId", this.user._id);
      freelancerData.append("userType", "freelancer");
      freelancerData.append("fullName", this.createFreelancerProfile.name);
      freelancerData.append(
        "emailAddress",
        this.createFreelancerProfile.emailValue
      );
      freelancerData.append("legalName", this.createFreelancerProfile.slugan);
      freelancerData.append("phoneNumber", this.phoneNumber.replace(/\s/g, ""));
      freelancerData.append("phoneCode", this.phoneCode);
      freelancerData.append(
        "passport",
        this.createFreelancerProfile.PassportID
      );
      freelancerData.append(
        "gender",
        this.createFreelancerProfile.selectedGender.value
      );

      /* ----------- Tab2  ----------*/
      freelancerData.append(
        "nationality",
        this.createFreelancerProfile.selectedNationality.value
      );
      freelancerData.append("industries", JSON.stringify(formatIndustries));
      freelancerData.append("locations", JSON.stringify(formatLocation));

      freelancerData.append("skills", JSON.stringify(formatSkills));
      freelancerData.append(
        "highlights",
        this.createFreelancerProfile.Highlights
      );
      freelancerData.append(
        "description",
        this.createFreelancerProfile.description
      );

      /* ----------- Tab3  ----------*/

      freelancerData.append("languages", JSON.stringify(formatLanguage));
      freelancerData.append("educations", JSON.stringify(formatDegree));
      freelancerData.append("certifications", JSON.stringify(formatInstitute));
      freelancerData.append("experiences", JSON.stringify(formatExperince));

      /* ----------- Tab4  ----------*/
      if (this.createFreelancerProfile.facebookUrl) {
        freelancerData.append(
          "freelancerFacebook",
          this.createFreelancerProfile.facebookUrl
        );
      }
      if (this.createFreelancerProfile.twitterUrl) {
        freelancerData.append(
          "freelancerTwitter",
          this.createFreelancerProfile.twitterUrl
        );
      }

      if (this.createFreelancerProfile.linkedinUrl) {
        freelancerData.append(
          "freelancerLinkedIn",
          this.createFreelancerProfile.linkedinUrl
        );
      }
      if (this.createFreelancerProfile.OtherUrl) {
        freelancerData.append(
          "freelancerOthers",
          this.createFreelancerProfile.OtherUrl
        );
      }

      /* ----------- Tab5  ----------*/
      freelancerData.append(
        "personalPhoto",
        this.createFreelancerProfile.PersonalPhoto
      );
      freelancerData.append(
        "freelancerProves",
        this.createFreelancerProfile.FreelancerProve
      );

      if (this.createFreelancerProfile.FreelancerCertificates) {
        for (const i of Object.keys(
          this.createFreelancerProfile.FreelancerCertificates
        )) {
          freelancerData.append(
            "certificates",
            this.createFreelancerProfile.FreelancerCertificates[i]
          );
        }
      }
      if (this.createFreelancerProfile.FreelancerRelevant) {
        for (const i of Object.keys(
          this.createFreelancerProfile.FreelancerRelevant
        )) {
          freelancerData.append(
            "otherDocuments",
            this.createFreelancerProfile.FreelancerRelevant[i]
          );
        }
      }

      /* ------------------------  */

      this.createFreelancer(freelancerData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.show = false;
          this.$router.replace("/pages/pricing", () => {
            this.$router.go(0);
          });
          // this.$router.push({ name: "pages-pricing" });
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error.message;
          this.show = false;
        });
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success && this.isValidNumber) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormExperience() {
      return new Promise((resolve, reject) => {
        this.$refs.ExperienceRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormFiles() {
      return new Promise((resolve, reject) => {
        this.$refs.attachmentRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
  created() {
    // $themeConfig.layout.menu.hidden = true;
    // console.log(store.state.appConfig.layout.menu.hidden);
    // console.log(store.state.appConfig.layout.footerType);
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.fetchSkills();
    this.configValidate();
  },
};
</script>

<style type="text/css">
[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}

[dir] .vs__selected {
  background-color: #366ce3;
}

.margin-top25 {
  margin-top: 25px;
}
</style>
<style scoped>
/* .breadcrumbs-top {
  margin-top: 65px;
} */
/* .company-form {
  margin-top: 25px;
} */
/* .company-form-footer {
  margin-top: 100px;
} */
</style>
