import axios from "axios";
export default {
  namespaced: true,
  state: {
    posts: [],
    postsCount: 0,
    postsLoaded: 0,
    isFinished: false,
    isLoading: false,
    trendingIdeas: [],
    trendingProjects: [],
    trendingProducts: [],
    trendingServices: [],
    whoToFollow: [],
    bookmarks: [],
  },
  mutations: {
    SET_POSTS_COUNT: (state, count) => {
      state.postsCount = count;
    },
    SET_POSTS: (state, posts) => {
      state.posts = posts;
      state.postsLoaded = posts.length;
    },
    PUSH_POSTS: (state, newPosts) => {
      newPosts.map((x) => {
        state.posts.push(x);
      });
      state.postsLoaded += newPosts.length;
    },
    SET_POSTS_LOADED: (state, count) => {
      state.postsLoaded = count;
    },
    SET_IS_LOADING: (state, status) => {
      state.isLoading = status;
    },
    SET_TRENDING_IDEAS: (state, ideas) => {
      state.trendingIdeas = ideas;
    },
    SET_TRENDING_PROJECTS: (state, projects) => {
      state.trendingProjects = projects;
    },
    SET_TRENDING_PRODUCTS: (state, products) => {
      state.trendingProducts = products;
    },
    SET_TRENDING_SERVICES: (state, services) => {
      state.trendingServices = services;
    },
    SET_WHO_TO_FOLLOW: (state, users) => {
      state.whoToFollow = users || [];
    },
    SET_BOOKMARKS: (state, bookmarks) => {
      state.bookmarks = bookmarks || [];
    },
    SET_IS_FINISHED: (state, value) => {
      state.isFinished = value;
    },
  },
  actions: {
    async getPostsData({ commit, state }, data) {
      try {
        commit("SET_IS_LOADING", true);
        const res = await axios.post("/timeline", data);

        if (data.get("page") <= 1) {
          commit("SET_POSTS", res.data.data);
          commit("SET_POSTS_LOADED", res.data.data.length);
        } else {
          commit("PUSH_POSTS", res.data.data);
        }
        commit("SET_POSTS_COUNT", res.data.count);
        commit("SET_IS_LOADING", false);

        /// lets update the finish if all posts are loaded
        if (state.postsLoaded >= state.postsCount) {
          commit("SET_IS_FINISHED", true);
        } else {
          commit("SET_IS_FINISHED", false);
        }
        commit("SET_BOOKMARKS", res.data.bookmarks);
      } catch (error) {
        commit("SET_POSTS_COUNT", 0);
        commit("SET_POSTS", []);
        commit("SET_IS_LOADING", false);
      }
    },
    /* Setters */
    setTrendingProducts({ commit }, data) {
      commit("SET_TRENDING_PRODUCTS", data);
    },
    setTrendingProjects({ commit }, data) {
      commit("SET_TRENDING_PROJECTS", data);
    },
    setTrendingServices({ commit }, data) {
      commit("SET_TRENDING_SERVICES", data);
    },
    setTrendingIdeas({ commit }, data) {
      commit("SET_TRENDING_IDEAS", data);
    },
    setWhoToFollow({ commit }, data) {
      commit("SET_WHO_TO_FOLLOW", data);
    },
    setBookmarks({ commit }, data) {
      commit("SET_BOOKMARKS", data);
    },
  },
  getters: {
    getPostsCount(state) {
      return state.postsCount;
    },
    getPosts(state) {
      return state.posts;
    },
    getIsLoading(state) {
      return state.isLoading;
    },

    /* Trending profiles */
    getTrendingServices(state) {
      return state.trendingServices;
    },
    getTrendingIdeas(state) {
      return state.trendingIeas;
    },
    getTrendingProjects(state) {
      return state.trendingProjects;
    },
    getTrendingProducts(state) {
      return state.trendingProducts;
    },
    getWhoToFollow(state) {
      return state.whoToFollow;
    },
    getBookmarks(state) {
      return state.bookmarks;
    },
    getPostsLoaded(state) {
      return state.postsLoaded;
    },
    getIsFinished(state) {
      return state.isFinished;
    },
  },
};
