import i18n from '@/libs/i18n'

export default [
  {
    header: 'Partner',
  },
  // {
  //   title: 'Dashboard',
  //   route: 'dashboard',
  //   icon: 'HomeIcon',
  // },
  {
    title:('dashboard.dashboard'),
    route: 'partner-dashboard',
    icon: 'HomeIcon',
  },
  {
    title:('dashboard.verifications'),
    route: 'verifications',
    icon: 'CheckCircleIcon',
  },
  {
    title:('dashboard.Setting'),
    icon: 'SettingsIcon',
    route: 'pages-account-setting',
  },
]
