import axios from "axios";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    currencies: [],
  },
  mutations: {
    SET_CURRENCIES(state, currencies) {
      state.currencies = currencies;
    },
  },
  actions: {
    async retreiveCurrencies({ commit }) {
      // eslint-disable-next-line no-return-await
      return await axios.get("currencies").then((response) => {
        commit("SET_CURRENCIES", response.data.data);
      });
    },
  },
  getters: {
    // getCurrencies(state) {
    //   return state.currencies;
    // },
    getCurrencies(state) {
      const formatCurrencies = [];
      state.currencies.forEach((element) => {
        formatCurrencies.push({
          _id: element._id,
          name:
            store.state.locale.locale == "ar"
              ? element.arabicName
                ? element.arabicName
                : element.name
              : element.name,
          short_name: element.short_name,
          symbol:
            store.state.locale.locale == "ar"
              ? element.arabicSymbol
                ? element.arabicSymbol
                : element.symbol
              : element.symbol,
          rate: element.rate,
          // shortName: element.shortName,
          shortName:
            store.state.locale.locale == "ar"
              ? element.arabicSymbol
              : element.shortName,
          short_name: element.short_name,
          symbolDirection: element.symbolDirection,
          isActive: element.isActive,
          flag: element.flag,
          arabicSymbol: element.arabicSymbol,
          id: element.id,
          value: element.value,
          text: element.text,
        });
      });
      return formatCurrencies;
    },
  },
};
