import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import i18n from "@/libs/i18n";
import VueViewer from "v-viewer";
import VueLoadmore from "vuejs-loadmore";
import { localize } from "vee-validate";
import { messaging } from "./firebase";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import "./global-components";

import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";
import "@/@fake-db/db";
Vue.use(VueLoadmore, {
  lang: "en-US",
});
Vue.use(VueViewer);
require("./store/subscriber/subscriber");
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Vue.component("vue-phone-number-input", VuePhoneNumberInput);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;
Vue.prototype.$messaging = messaging;
store.dispatch("auth/attempt", localStorage.getItem("accessToken")).then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});

Vue.mixin({
  methods: {
    configValidate() {
      if (this.$store.state.locale.locale == "ar") {
        localize("ar");
      } else {
        localize("en");
      }
    },

    currencyExchange(price, oldSymbol) {
      if (price === 1) {
        if (this.$store.state.locale.locale == "ar") {
          return "مزاد";
        }
        return "Auction";
      }
      let x;
      let myCurrency;
      const allCurrencies = this.$store.state.currency.currencies;
      myCurrency = JSON.parse(localStorage.getItem("currency"));
      const currencyOld = allCurrencies.find((x) => x.symbol === oldSymbol);
      if (myCurrency.symbol === "$") {
        return price / currencyOld.rate;
      }
      const priceInDolar = price / currencyOld.rate;

      return priceInDolar * myCurrency.rate;
    },
  },
});
Vue.prototype.$maxHighlightsUser = 500;
Vue.prototype.$sizeFilesUser = 4000;
Vue.prototype.$maxHighlightsProfile = 500;
Vue.prototype.$sizeFilesProfile = 4000;
Vue.prototype.$maxSkillsUser = 4;
Vue.prototype.$maxDescriptionAuction = 50;
Vue.prototype.$myCurrency = store.state.myCurrency.myCurrency;
