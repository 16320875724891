var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-form"},[_c('Header',{}),_c('h2',{staticClass:"margin-top25"}),_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('form-wizard',{staticClass:"mb-3 pt-1",attrs:{"color":"#366ce3","title":_vm.$t('CompanyForm.Register_Company'),"subtitle":_vm.$t('CompanyForm.Complete_your_account'),"shape":"square","finish-button-text":_vm.$t('common.Submit'),"next-button-text":_vm.$t('common.Next'),"back-button-text":_vm.$t('common.Previous')},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":_vm.$t('CompanyForm.Company_Details'),"before-change":_vm.validationForm,"icon":"feather icon-file-text"}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("CompanyForm.Company_Details"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("CompanyForm.Enter_Company_Details"))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Company_name'),"label-for":"Fomraml_name"}},[_c('validation-provider',{attrs:{"name":"Company name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Fomraml_name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.Company_name')},model:{value:(_vm.createCompanyProfile.name),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "name", $$v)},expression:"createCompanyProfile.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Company_email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"john.doe@email.com"},model:{value:(_vm.createCompanyProfile.emailValue),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "emailValue", $$v)},expression:"createCompanyProfile.emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Short_name'),"label-for":"slugan"}},[_c('validation-provider',{attrs:{"name":"slugan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slugan","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.Short_name')},model:{value:(_vm.createCompanyProfile.slugan),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "slugan", $$v)},expression:"createCompanyProfile.slugan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_vm._v(" "+_vm._s(_vm.myCountry)+" "),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Phone_Number'),"label-for":"Phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"}},[_c('VuePhoneNumberInput',{attrs:{"color":"#ea5455","error-color":"#ea5455","default-country-code":_vm.CountryCode,"translations":_vm.$store.state.locale.locale == 'ar'
                        ? _vm.translationsArabic
                        : _vm.translations},on:{"update":_vm.onUpdate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),(!_vm.isValidNumber)?_c('small',{staticClass:"text-danger isValid"},[_vm._v(_vm._s(_vm.$t("common.phone_Invalid")))]):_vm._e()],1)],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('CompanyForm.Company_Info'),"icon":"feather icon-user","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("CompanyForm.Company_Info"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("CompanyForm.Enter_Company_Info")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('label',{attrs:{"for":"example-datepicker"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.Date_of_establishment"))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Establishment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example-datepicker","state":errors.length > 0 ? false : null,"max":_vm.max,"locale":_vm.$store.state.locale.locale},model:{value:(_vm.createCompanyProfile.establishment),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "establishment", $$v)},expression:"createCompanyProfile.establishment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"EBITDA"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.EBITDA"))+" ")]),_c('validation-provider',{attrs:{"name":"EBITDA","rules":"required|between:-100,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"EBITDA","type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.EBITDA')},model:{value:(_vm.createCompanyProfile.EBITDA),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "EBITDA", $$v)},expression:"createCompanyProfile.EBITDA"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Monthly-Sales"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.average_monthly_sales"))+" ")]),_c('validation-provider',{attrs:{"name":"Monthly Sales","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Monthly-Sales","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.average_monthly_sales')},model:{value:(_vm.createCompanyProfile.MonthlySales),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "MonthlySales", $$v)},expression:"createCompanyProfile.MonthlySales"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Annual-Sales"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.reported_yearly_sales"))+" ")]),_c('validation-provider',{attrs:{"name":"Annual Sales","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Annual-Sales","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.reported_yearly_sales')},model:{value:(_vm.createCompanyProfile.AnnualSales),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "AnnualSales", $$v)},expression:"createCompanyProfile.AnnualSales"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Industry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.company_belong_to'),"label-for":"Industry","state":errors.length > 0 ? false : null}},[_c('div',{attrs:{"id":"app"}},[_c('treeselect',{attrs:{"multiple":true,"placeholder":_vm.$t('CompanyForm.company_belong_to'),"options":_vm.industries,"normalizer":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.normalizerArabic
                          : _vm.normalizer},on:{"input":_vm.handeTreeSelect},model:{value:(_vm.createCompanyProfile.industries),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "industries", $$v)},expression:"createCompanyProfile.industries"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.tangible_and_intangible'),"label-for":"Assets"}},[_c('validation-provider',{attrs:{"name":"Assets","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Assets","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.tangible_and_intangible')},model:{value:(_vm.createCompanyProfile.Assets),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "Assets", $$v)},expression:"createCompanyProfile.Assets"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.physical_assets'),"label-for":"Physical-Assets"}},[_c('validation-provider',{attrs:{"name":"Physical Assets","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Physical-Assets","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.physical_assets')},model:{value:(_vm.createCompanyProfile.PhysicalAssets),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "PhysicalAssets", $$v)},expression:"createCompanyProfile.PhysicalAssets"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"categories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.interested_in'),"label-for":"categories","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"categories","options":_vm.categories,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createCompanyProfile.SelectedCategories),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "SelectedCategories", $$v)},expression:"createCompanyProfile.SelectedCategories"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(
                _vm.createCompanyProfile.SelectedCategories.value ===
                '62a1e60144fbaf36185a39da'
              )?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Currency'),"label-for":"","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createCompanyProfile.SelectedCurrency),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "SelectedCurrency", $$v)},expression:"createCompanyProfile.SelectedCurrency"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,953585368)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":"investment amount","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.investment_amount_seeking'),"label-for":"InvestmentPrice","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"InvestmentPrice","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.investment_amount_seeking')},model:{value:(_vm.createCompanyProfile.Price),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "Price", $$v)},expression:"createCompanyProfile.Price"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1278426823)})],1)],1)],1):_vm._e(),(
                _vm.createCompanyProfile.SelectedCategories.value ===
                '62a1e61144fbaf36185a39de'
              )?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Currency'),"label-for":"","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createCompanyProfile.SelectedCurrency),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "SelectedCurrency", $$v)},expression:"createCompanyProfile.SelectedCurrency"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,953585368)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":"loan Amount","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.loan_amount_seeking'),"label-for":"loanAmount","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"loanAmount","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.loan_amount_seeking')},model:{value:(_vm.createCompanyProfile.Price),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "Price", $$v)},expression:"createCompanyProfile.Price"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2202798887)})],1)],1)],1):_vm._e(),(
                _vm.createCompanyProfile.SelectedCategories.value ===
                '62a1e64944fbaf36185a39e2'
              )?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Currency'),"label-for":"","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createCompanyProfile.SelectedCurrency),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "SelectedCurrency", $$v)},expression:"createCompanyProfile.SelectedCurrency"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,953585368)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":"Selling or Leasing","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.selling_leasing'),"label-for":"selling","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"selling","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.selling_leasing')},model:{value:(_vm.createCompanyProfile.Price),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "Price", $$v)},expression:"createCompanyProfile.Price"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,167919751)})],1)],1)],1):_vm._e(),(
                _vm.createCompanyProfile.SelectedCategories.value ===
                '62a1e5eb44fbaf36185a39d6'
              )?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"sale type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.sell_your_company'),"label-for":"saleType","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"saleType","options":_vm.$store.state.locale.locale == 'ar'
                        ? _vm.createCompanyProfile.SaleTypeOptionArabic
                        : _vm.createCompanyProfile.SaleTypeOption,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createCompanyProfile.selectedSaleType),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "selectedSaleType", $$v)},expression:"createCompanyProfile.selectedSaleType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1202487316)})],1):_vm._e(),(_vm.createCompanyProfile.selectedSaleType.value === '1')?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Currency'),"label-for":"","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createCompanyProfile.SelectedCurrency),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "SelectedCurrency", $$v)},expression:"createCompanyProfile.SelectedCurrency"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,953585368)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Price'),"label-for":"Price","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"Price","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.Price')},model:{value:(_vm.createCompanyProfile.Price),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "Price", $$v)},expression:"createCompanyProfile.Price"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3891499879)})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.License_Number'),"label-for":"License-Number"}},[_c('validation-provider',{attrs:{"name":"License Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"License-Number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.License_Number')},model:{value:(_vm.createCompanyProfile.LicenseNumber),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "LicenseNumber", $$v)},expression:"createCompanyProfile.LicenseNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"company type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.legal_entity_type'),"label-for":"Company-Type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Company-Type","options":_vm.$store.state.locale.locale === 'ar'
                        ? _vm.createCompanyProfile.CompanyTypeOptionArabic
                        : _vm.createCompanyProfile.CompanyTypeOption,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createCompanyProfile.SelectedCompanyType),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "SelectedCompanyType", $$v)},expression:"createCompanyProfile.SelectedCompanyType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.overview_your_business'),"label-for":"Highlights"}},[_c('validation-provider',{attrs:{"name":"Highlights","rules":("required|max:" + _vm.$maxHighlightsUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Highlights","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.overview_your_business_p')},model:{value:(_vm.createCompanyProfile.Highlights),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "Highlights", $$v)},expression:"createCompanyProfile.Highlights"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.description'),"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.description')},model:{value:(_vm.createCompanyProfile.description),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "description", $$v)},expression:"createCompanyProfile.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('CompanyForm.Company_Address'),"icon":"feather icon-map-pin","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("CompanyForm.Company_Address"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("CompanyForm.Enter_Your_Address")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Company_country'),"label-for":"Country","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Country","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},on:{"input":function($event){return _vm.retreiveStates(
                        _vm.createCompanyProfile.selectedCountry.value
                      )}},model:{value:(_vm.createCompanyProfile.selectedCountry),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "selectedCountry", $$v)},expression:"createCompanyProfile.selectedCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Company_state'),"label-for":"State","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"State","options":_vm.statesOptions,"label":"text"},model:{value:(_vm.createCompanyProfile.selectedState),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "selectedState", $$v)},expression:"createCompanyProfile.selectedState"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Company_city'),"label-for":"City"}},[_c('validation-provider',{attrs:{"name":"City","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"City","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.Company_city')},model:{value:(_vm.createCompanyProfile.city),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "city", $$v)},expression:"createCompanyProfile.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Company_street'),"label-for":"otherInfo"}},[_c('validation-provider',{attrs:{"name":"otherInfo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"otherInfo","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('CompanyForm.Company_street')},model:{value:(_vm.createCompanyProfile.companyStreet),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "companyStreet", $$v)},expression:"createCompanyProfile.companyStreet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('CompanyForm.Social_Links'),"icon":"feather icon-link","before-change":_vm.validationFormSocial}},[_c('validation-observer',{ref:"socialRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("CompanyForm.Social_Links"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("CompanyForm.Enter_Your_Social")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Facebook'),"label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.createCompanyProfile.facebookUrl),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "facebookUrl", $$v)},expression:"createCompanyProfile.facebookUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Twitter'),"label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"placeholder":"https://twitter.com/abc"},model:{value:(_vm.createCompanyProfile.twitterUrl),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "twitterUrl", $$v)},expression:"createCompanyProfile.twitterUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.LinkedIn'),"label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false : null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.createCompanyProfile.linkedinUrl),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "linkedinUrl", $$v)},expression:"createCompanyProfile.linkedinUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Website'),"label-for":"Other"}},[_c('validation-provider',{attrs:{"name":"Other","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Other","state":errors.length > 0 ? false : null,"placeholder":"https://other.com/abc"},model:{value:(_vm.createCompanyProfile.OtherUrl),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "OtherUrl", $$v)},expression:"createCompanyProfile.OtherUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('CompanyForm.Attachment'),"icon":"feather icon-file-plus","before-change":_vm.validationFormFiles}},[_c('validation-observer',{ref:"attachmentRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("CompanyForm.Attachment"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("CompanyForm.Upload_your_files"))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Company image","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"profile-picture"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.main_company_image"))+" ")]),_c('b-form-file',{attrs:{"required":"","accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","multiple":false,"browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createCompanyProfile.ProfilePicture),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "ProfilePicture", $$v)},expression:"createCompanyProfile.ProfilePicture"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Company Documents","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"Company-Documents"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.company_documents"))+" ")]),_c('b-form-file',{attrs:{"accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createCompanyProfile.CompanyDocuments),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "CompanyDocuments", $$v)},expression:"createCompanyProfile.CompanyDocuments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Company Proof","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"Proof-Business"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.proof_of_business"))+" ")]),_c('b-form-file',{attrs:{"accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createCompanyProfile.ProofBusiness),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "ProofBusiness", $$v)},expression:"createCompanyProfile.ProofBusiness"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"company-photos"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.company_images"))+" ")]),_c('validation-provider',{attrs:{"name":"Company images","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":"image/jpeg, image/png, image/gif","multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createCompanyProfile.CompanyPhotos),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "CompanyPhotos", $$v)},expression:"createCompanyProfile.CompanyPhotos"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[(_vm.errorMessage)?_c('b-alert',{staticClass:"warning p-1",staticStyle:{"color":"red"},attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)],1)],1)],1)],1)],1),_c('Footer',{staticClass:"company-form-footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }