<template>
  <b-card :title="$t('common.Experiences')">
    <div>
      <b-form ref="form" :style="{ height: trHeight }">
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in experiences"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col cols="12" md="3">
            <b-form-group :label="$t('common.Experience')" label-for="Major">
              <b-form-input
                id="Major"
                v-model="item.name"
                type="text"
                :placeholder="$t('common.Experience')"
              />
            </b-form-group>
          </b-col>
          <!-- Company Name -->
          <b-col cols="12" md="3">
            <b-form-group :label="$t('common.Company')" label-for="Company">
              <b-form-input
                id="Company"
                v-model="item.company"
                type="text"
                :placeholder="$t('common.Company')"
              />
            </b-form-group>
          </b-col>
          <!--country-->
          <b-col cols="12" md="2">
            <b-form-group :label="$t('common.Country')">
              <v-select
                v-model="item.country"
                placeholder="Select country"
                label="text"
                :options="countries"
                style="color: black"
              />
            </b-form-group>
          </b-col>
          <!-- Years -->
          <b-col cols="12" md="2">
            <b-form-group :label="$t('common.Years')" label-for="Years">
              <b-form-input
                id="Years"
                v-model="item.years"
                type="number"
                :placeholder="$t('common.Years')"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col cols="12" md="2" sm="6" class="pt-2 mt-0 mb-60">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>{{ $t("common.Delete") }}</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>{{ $t("common.AddNew") }}</span>
    </b-button>
  </b-card>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormRating,
  BFormDatepicker,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCardCode,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRating,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: ["experiences"],
  data() {
    return {
      nextTodoId: 2,

      name: "",
      company: "",
      country: "",
      years: "",
    };
  },
  computed: {
    ...mapGetters({
      countries: "country/getCountries",
    }),
  },
  mounted() {
    this.initTrHeight();
    this.fetchCountries();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    ...mapActions({
      fetchCountries: "country/retreiveCountries",
    }),
    repeateAgain() {
      this.experiences.push({
        id: (this.nextTodoId += this.nextTodoId),
        name: this.name,
        company: this.company,
        country: this.country.value,
        years: this.years,
        // experience: this.experience,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.experiences.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
